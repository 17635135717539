import React, {FC, memo} from "react";
import {ITkProductModel} from "../../models/product";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {formatMoney} from "../../utils/number-utils";
import TkProductAttributes from "../../components/product/TkProductAttributes";
import {tk_route_manufacturer} from "../TkSearchView";
import {Link} from "react-router-dom";
import {tk_route_seller} from "../TkSellerInfoView";
import {isBlank} from "../../utils/string-utils";
import TkAvailabilityCard from "../../components/particles/TkAvailabilityCard";

const Ref:FC<{
    product: ITkProductModel
}> = ({product}) => {
    const {manufacturerReference, manufacturerCode, externalId, _id} = product
    if (isBlank(manufacturerReference) && isBlank(manufacturerCode)) {
        return <span className='TkProductDetailView__teky-id'>teky-{_id} | pleno-{externalId}</span>
    }

    return <p className='TkProductDetailView__reference'>{manufacturerReference}{(manufacturerReference && manufacturerCode) && <span className="c-a-g"> | </span>}{manufacturerCode} <br/> <span className='TkProductDetailView__teky-id'>teky-{_id} | pleno-{externalId}</span></p>
}

const ProductTitleArea: React.FC<{
    product: ITkProductModel | null | undefined,
    quantity: number,
    setQuantity: (quantity: number) => void,
    add: () => void,
    showSkeleton?: boolean
}> = ({product, showSkeleton = false}) => {
    if (showSkeleton) {
        return (
            <div style={{paddingTop: 10}}>
                <TkSkeleton width='90%' height='2em' />
                <TkSkeleton width='10%' height='1.2em' />
                <TkSkeleton width='20%' height='1.2em' />
                <TkSkeleton width='30%' height='1.2em' />
                <TkSkeleton width='40%' height='1.2em' />
                <TkSkeleton width='40%' height='1.2em' />
            </div>
        );
    }

    if (!product) return <div/>;

    const {name, manufacturerName, manufacturerId, price, promoPrice, unitCommercialName, minimumSaleQuantity, controlMultiplicity} = product;

    return (
        <div>
            <h1 className='TkProductDetailView__title'>{name}</h1>

            <Ref product={product}/>

            <p className='TkProductDetailView__brand'>
                por <Link to={tk_route_manufacturer(manufacturerId)} onClick={(e) => e.stopPropagation()}>{manufacturerName}</Link>
                
            </p>
            
            <p className='TkProductDetailView__price'>
            <del className='TkProductDetailView__default-price'>{formatMoney(price)}</del>
                <span className='TkProductDetailView__sell-by'></span><ins>{formatMoney(promoPrice)}</ins>
                <span style={{color: 'black', fontSize: '14px', marginLeft: '5px'}}> por {unitCommercialName ? unitCommercialName.toLocaleLowerCase() : 'unidade'}</span> {controlMultiplicity && minimumSaleQuantity > 1 && unitCommercialName.toLocaleLowerCase() == "metro" && <span style={{color: 'black', marginLeft: '5px', fontSize: '14px'}}>&#40;{formatMoney(promoPrice * minimumSaleQuantity)} por rolo&#41;</span>}
            </p>
            
        <TkAvailabilityCard  product={product} />
        <p className='TkProductDetailView__sell-by'>Vendido por <Link to={tk_route_seller}>Plenobras</Link></p>
            <div className='TkProductDetailView__product-info-1'>
                <h2>Informações sobre o produto</h2>
                <TkProductAttributes attributes={product.attributes} extraInfo={product.extraInfo}/>
            </div>
        </div>
    );
};

export default memo(ProductTitleArea);