import React from "react";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkSlider from "../../components/particles/TkSlider";
import TkCarouselBestManufacturers from "../../components/product/./TkCarouselBestManufacturers";
import TkCarouselBestCategories from "../../components/product/./TkCarouselBestCategories";
import TkCarouselBestClasses from "../../components/product/./TkCarouselBestClasses";
import TkCarouselBestProducts from "../../components/product/./TkCarouselBestProducts";
import TkCarouselOtherProducts from "../../components/product/TkCarouselOtherProducts";

const MediumLargeScreen: React.FC = () => {
    const slides = [
        {
        img: "https://teky.s3.sa-east-1.amazonaws.com/banners/compre_e_retire_teky.webp",
        text: "",
        style: {
            color: "#ffffff",
            fontSize: "14px",
            marginTop: "3rem",
        },
    }, {
        img: "https://teky.s3.sa-east-1.amazonaws.com/banners/compre_online_teky.webp",
        text: "",
        style: {
            color: "#000000",
            fontSize: "14px",
            marginTop: "3rem",
        },
    }, {
        img: "https://teky.s3.sa-east-1.amazonaws.com/banners/toda_linha_de_produtos_tecnicos_teky.webp",
        text: "",
        style: {
            color: "#000000",
            fontSize: "14px",
            marginTop: "3rem",
        },
    }];

    return <div className="TkWorldContainer">
        <TkDivisionsTop/>
        <TkSlider slides={slides} autoPlay={true}/>

        <TkCarouselBestProducts/>

        <TkCarouselBestManufacturers/>

        <TkCarouselBestCategories/>

        <TkCarouselBestClasses/>

        <TkCarouselOtherProducts/>
    </div>

};
export default MediumLargeScreen;
