import React, {useEffect, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {Link, useHistory, useLocation} from "react-router-dom";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import {animated as a, useTrail} from "react-spring";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {tk_route_sign_up} from "../TkSignUpView";
import TkCopyright from "../../components/particles/TkCopyright";
import {useSimpleInput} from "../../hooks/UseSimpleInput";

import './style.scss'
import {isBlank} from "../../utils/string-utils";
import {tk_route_sign_in} from "../TkSignInView";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";

export const tk_route_change_password = '/alterar-senha';

interface StateChangePassword {
    errors: string[]
    hasErrors: boolean
    doing: boolean
    success: boolean
}

const TkChangePasswordView: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const {changeForgotPassword} = useTkUser()
    const {goToPageError} = useTkAppErrors()
    const [token, setToken] = useState('')
    const [email, setEmail] = useState('')
    const {value: newPassword, bind: bindNewPassword} = useSimpleInput('')
    const {value: newPasswordConfirmation, bind: bindNewPasswordConfirmation} = useSimpleInput('')

    useEffect(() => {
        const p = new URLSearchParams(location.search);
        setEmail(p.get('email'))
        setToken(p.get('token'))
    }, [location])

    const initialState: StateChangePassword = {
        hasErrors: false,
        errors: [],
        doing: false,
        success: false,
    };

    const [state, setState] = useState<StateChangePassword>({...initialState});

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(state.errors.length, {
        config,
        opacity: state.hasErrors ? 1 : 0,
        from: {opacity: 0},
    });

    function onSubmit(e: any) {
        e.preventDefault()

        let newState = {...initialState};

        let hasErrors = false;
        let messages = []

        if (isBlank(newPassword)) {
            hasErrors = true
            messages.push('Informe a nova senha')

        } else if (newPassword.length < 8) {
            hasErrors = true
            messages.push('Nova senha deve ter no mínimo 8 caracteres')
        }

        if (isBlank(newPasswordConfirmation)) {
            hasErrors = true
            messages.push('Informe a confirmação de nova senha')
        } else if (newPassword !== newPasswordConfirmation) {
            hasErrors = true
            messages.push('Confirmação de senha diferente')
        }

        newState = {
            ...newState,
            hasErrors,
            errors: [...messages]
        }

        setState(newState);

        if (!newState.hasErrors) {
            setState({
                ...newState,
                doing: true
            });

            changeForgotPassword(token, newPassword, newPasswordConfirmation)
                .then(result => {
                    newState = {
                        ...newState,
                        hasErrors: false,
                        errors: [],
                        success: true
                    };

                    if (result.success) setTimeout(() => history.push(tk_route_sign_in), 3000);
                    else {
                        newState.hasErrors = true;
                        newState.success = false
                        newState.errors= result.errors.map(e => e.message)
                    }
                })
                .catch(goToPageError)
                .finally(() => {
                    setState({
                        ...newState,
                        doing: false
                    })
                });
        }

        return false
    }

    return <div className='TkChangePassView'>
        <TkHeadSEO title='Alterar a senha | Teky'/>

        <div className="container m-t-1rem">
            <div className='t-a-c'>
                <TkLogoBuilder/>
            </div>
            <p className='TkChangePassView__tips t-a-c'>Informe os dados abaixo:</p>

            <br/>
            <form className='TkChangePassView__form' onSubmit={onSubmit} noValidate>
                <div className="form-group">
                    <label htmlFor='userChangePasswordEmail'>E-mail</label>
                    <input id='userChangePasswordEmail'
                           type='email'
                           readOnly={true}
                           placeholder='E-mail'
                           className='form-control'
                           autoComplete='email'
                           value={email}
                           required/>
                </div>

                <div className="form-group">
                    <label htmlFor='userChangePasswordNewPass'>Informe nova senha</label>
                    <input id='userChangePasswordNewPass'
                           type='password'
                           placeholder='Nova senha'
                           className='form-control'
                           {...bindNewPassword} required/>
                </div>

                <div className="form-group">
                    <label htmlFor='userChangePasswordNewPassConfirm'>Confirme nova senha</label>
                    <input id='userChangePasswordNewPassConfirm'
                           type='password'
                           placeholder='Confirmação de nova senha'
                           className='form-control'
                           {...bindNewPasswordConfirmation} required/>
                </div>

                <div className='TkChangePassView__form-msgs'>
                    {trail.map((props, index) => (
                        <a.div
                            key={index}
                            className='alert alert-danger'
                            style={props}>
                            {state.errors[index]}
                        </a.div>
                    ))}

                    {state.success  &&
                    <div className='alert alert-success'>Senha alterada com sucesso!</div>}
                </div>

                <br/>
                <button type='submit'
                        disabled={state.doing || state.success}
                        className='btn btn-primary btn-block'>
                    {state.doing || state.success ? <TkSvgIcon className='rotate-1-seg' icon='sync-solid'/> :
                        <span>ALTERAR SENHA</span>}
                </button>

                <div className='form-group t-a-r m-t-1rem TkChangePassView__sign-up'>Não possui cadastro? <Link
                    to={tk_route_sign_up} title='Faça seu cadastro'>Faça seu cadastro</Link></div>
                <br/>
                <div className='form-group t-a-c '>
                    <TkCopyright/>
                </div>
            </form>
        </div>
    </div>
};

export default TkChangePasswordView
