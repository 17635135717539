import React, {memo} from "react";
import './style.scss'
import TkSvgIcon from "../../particles/TkSvgIcon";
import {Link} from "react-router-dom";
import {tk_route_my_cart} from "../../../views/TkMyCartView";
import {useTkCart} from "../../../context/TkContext";

const TkTopCart: React.FC = () => {
    const {currentCart} = useTkCart();
    const quantity = currentCart && currentCart.items ? currentCart.items.length : 0;

    return (
        <Link to={tk_route_my_cart} className='TkTopCart' title="Carrinho de Compras">
            <TkSvgIcon icon='shopping-cart-solid'/>
            {quantity > 0 && <span className='TkTopCart__badge'>{quantity > 9 ? '9+' : quantity}</span>}
        </Link>
    );
};


export default memo(TkTopCart);
