import React from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";

export const tk_route_shopkeeper_and_partners = '/lojistas-e-parceiros';

const TkShopkeeperAndPartnersView: React.FC = () => {
    return <>
        <TkHeadSEO title='Lojistas e Parceiros | Teky'
                   description='Seja um lojista ou parceiro da Teky. Saiba os benefícios.'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_shopkeeper_and_partners,
                    label: 'Lojistas e Parceiros'
                }
            ]}/>

            <h1>Lojistas e Parceiros</h1>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkShopkeeperAndPartnersView
