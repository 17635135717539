import React, {useEffect, useState} from "react";
import {ITkProductModel, ITkProductVideoModel,} from "../../models/product";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import Hammer from "hammerjs";
import {MAX_BACKORDER} from "../../utils/constants";
import {isBlank} from "../../utils/string-utils";
import {formatMoney} from "../../utils/number-utils";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {Link} from "react-router-dom";
import {tk_route_exchanges_and_devolution} from "../TkExchangesAndDevolutionView";
import {useTkCart} from "../../context/TkContext";
import TkProductAttributes from "../../components/product/TkProductAttributes";
import TkDots from "../../components/particles/TkDots";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {tk_route_manufacturer} from "../TkSearchView";
import TkQuantityOptions from "../../components/cart/TkQuantityOptions";
import YoutubeLazyPlayer from "./_youtubeLazyPlayer";
import TkShipmentArea, {TkShipmentAreaType,} from "../../components/shipment/TkShipmentArea";
import TkListCombo from "../../components/list/TkListCombo"
import {tk_route_seller} from "../TkSellerInfoView";
import TkAvailabilityCard from "../../components/particles/TkAvailabilityCard";
import TkCarouselSimilarityProducts from "../../components/product/TkCarouselSimilarityProducts";
import TkCarouselCartBasedProducts from "../../components/product/TkCarouselCartBasedProducts";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg';

const ImageArea: React.FC<{
  product: ITkProductModel;
}> = ({product}) => {
  const [activeImg, setActiveImg] = useState(0);
  const [ref, setRef] = useState();
  const [height, setHeight] = useState(window.innerWidth);

  let images: string[] = [];

  useEffect(() => {
    if (ref && images.length > 1) {
      const hammer = new Hammer(ref);

      hammer.on("swipeleft", () =>
        setActiveImg((i) => (i + 1) % images.length)
      );
      hammer.on("swiperight", () =>
        setActiveImg((i) => (i - 1 + images.length) % images.length)
      );

      return () => hammer.destroy();
    }
  }, [ref]);

  if (!product) return <></>;

  if (!isBlank(product.thumbnail)) images.push(product.thumbnail);

  if (product.images && product.images.length > 0)
    images = images.reduce((obj, img) => {
      if (obj.indexOf(img) < 0) obj.push(img);
      return obj;
    }, images);

  if (images.length === 0) images.push(NoPhoto);

  return (
    <div className="TkProductDetailView__container-img-small">
      <div ref={setRef} style={{height: `${height}px`}}>
        <TkProportionalImage
          key={activeImg}
          listenHeight={setHeight}
          width={100}
          height={100}
          alt={product?.name}
          url={images[activeImg]}
          plainB={NoPhoto}
        />
      </div>
      <TkDots active={activeImg} count={images.length}/>
    </div>
  );
};

const SmallScreen: React.FC<{
  product: ITkProductModel
  loadingProduct: boolean
  add: () => void
  setQuantity: (qtd: number) => void
  quantity: number
}> = ({
        product,
        loadingProduct,
        add,
        quantity,
        setQuantity,
      }) => {
  const [showCustomQuantity, setShowCustomQuantity] = useState(false);
  const {updatingCart} = useTkCart();
  const [isBackorder, setIsBackorder] = useState(false);

  const isBackorderOnly = product?.leadTime && !product?.stock

  const onChangeQuantity = (e: any) => {
    setShowCustomQuantity(e.target.value === "more");

    if (e.target.value !== "more") {
      setQuantity(parseInt(e.target.value));
    }
  };

  if (!product || loadingProduct)
    return (
      <>
        <div className="TkProductDetailView p-15px">
          <TkSkeleton width="90%" height="50px"/>
          <TkSkeleton width="100%" height="380px"/>
          <TkSkeleton width="100%" height="40px"/>
          <TkSkeleton width="50%" height="20px"/>
          <TkSkeleton width="50%" height="50px"/>
        </div>
      </>
    );
  const {promoPrice, unitCommercialName, controlMultiplicity, minimumSaleQuantity} = product

  return <div className="TkProductDetailView__float p-15px">
    <h1 className="TkProductDetailView__title">{product.name}</h1>
    <p className="TkProductDetailView__sell-by">
      por&nbsp;
      <Link
        to={tk_route_manufacturer(product.manufacturerId)}
        onClick={(e) => e.stopPropagation()}
      >
        {product.manufacturerName}
      </Link>
    </p>
    {product.manufacturerReference && (
      <p className="f-s-14px">
        {product.manufacturerReference} {(product.manufacturerReference && product.manufacturerCode) && <span className="c-a-g"> | </span>} {product.manufacturerCode}
        <br/>
        <span className="TkProductDetailView__teky-id">
            teky-{product._id} | pleno-{product.externalId}
          </span>
      </p>
    )}
    {!product.manufacturerReference && (
      <p className="f-s-14px">
        <span className="TkProductDetailView__teky-id">
            teky-{product._id} | pleno-{product.externalId}
          </span>
      </p>
    )}

    <div className="m-t-15px">
      <ImageArea product={product}/>
    </div>

    <p className="TkProductDetailView__price">
      <span className="TkProductDetailView__sell-by"></span>
      <ins>{formatMoney(product.promoPrice)}</ins>
      <span style={{color: "#cecece"}}>
          &nbsp;
        por&nbsp;
        {product.unitCommercialName
          ? product.unitCommercialName.toLocaleLowerCase()
          : "unidade"}
        </span>
        <br/> 
        {controlMultiplicity && minimumSaleQuantity > 1 && unitCommercialName.toLocaleLowerCase() == "metro" && <span style={{color: "black"}}>&#40;{formatMoney(promoPrice * minimumSaleQuantity)} por rolo&#41;</span>}
    </p>
    
    {showCustomQuantity ? (
      <input
        type="number"
        className="quantity form-control form-control-sm"
        min={1}
        max={product?.leadTime ? MAX_BACKORDER : product.stock}
        value={quantity}
        onChange={(e) =>
          setQuantity(parseInt((e.target.value || "0").replace(/\D+/gi, "")))
        }
      />
    ) : (
      (product.stock || isBackorder) && <select
        value={quantity}
        onChange={onChangeQuantity}
        className="quantity form-control form-control-sm"
      >
        <TkQuantityOptions stock={product?.stock} controlMultiplicity={product?.controlMultiplicity} minimumSaleQuantity={product.minimumSaleQuantity} isLeadTime={product.leadTime && product.leadTime > 0}/>
      </select>
    )}

    <TkAvailabilityCard product={product} />
    
    {isBackorderOnly && (
      <div>
        {!isBackorder &&
          <button
            type="button"
            className="btn btn-ice-gray btn-sm"
            onClick={() => setIsBackorder(true)}
          >
            {'Encomendar'}
          </button>
        }
      </div>
    )}

    <p className="TkProductDetailView__sell-by">Vendido por <Link to={tk_route_seller}>Plenobras</Link></p>


    {(!isBackorderOnly || isBackorder) && <>
      <>
        <span className="f-s-14px">Formas de Pagamento</span>
        <br/>
        <ul className="list-payment">
          <li>
            <TkSvgIcon icon="visacard"/>
          </li>
          <li>
            <TkSvgIcon icon="mastercard"/>
          </li>
          <li>
            <TkSvgIcon icon="pix"/>
          </li>
          <li>
            <TkSvgIcon icon="boleto"/>
          </li>
        </ul>
      </>
      {product.isActive ?
        <button
          type="button"
          title="Adicionar ao carrinho de compras"
          className="button-2"
          disabled={updatingCart}
          onClick={add}
        >
          {updatingCart ? (
            <TkSvgIcon icon="sync-solid" className="rotate-1-seg m-r-1em"/>
          ) : (
            <TkSvgIcon icon="shopping-cart-solid" className="m-r-1em"/>
          )}
          <b>{updatingCart ? "Adicionando ao carrinho..." : "Adicionar ao carrinho"}</b>
        </button> : <></>
      }
    </>}
    <TkListCombo product={product}/>

    <p className="TkProductDetailView__policy">
      Consulte nossa política de&nbsp;
      <Link
        to={tk_route_exchanges_and_devolution}
        title="Veja nossa política de Trocas e Devoluções"
      >
        Trocas e Devoluções
      </Link>
    </p>
    <div className="TkProductDetailView__shipment m-t-20px">
      <div className="t-a-c"><b>Consultar entrega e frete</b></div>
      <div className="m-t-20px">
        <TkShipmentArea
          type={TkShipmentAreaType.product_detail}
          product={product}
          quantity={quantity}
        /></div>

    </div>

    <div className="TkProductDetailView__product-info-1 m-t-20px">
      <h2 className="t-a-c f-s-16px">Informações sobre o produto</h2>
      <TkProductAttributes attributes={product.attributes} extraInfo={product.extraInfo}/>
    </div>


    {!isBlank(product.technicalDetails) && (
      <>
        <h2 className="t-a-c m-t-40px f-s-16px">Detalhes Técnicos</h2>
        <p>{product.technicalDetails}</p>
      </>
    )}

    {!isBlank(product.additionalInfos) && (
      <>
        <h2 className="t-a-c m-t-40px f-s-16px">
          Informações complementares
        </h2>
        <p>{product.additionalInfos}</p>
      </>
    )}

    <div className="p-15px m-t-40px">
      <h3 className="t-a-c f-s-16px">{product?.videos?.length > 0 ? "Teky Experts" : "Quem Somos"}</h3>
      {product?.videos?.length > 0 ? (
        product.videos.map((video: ITkProductVideoModel) => (
          <YoutubeLazyPlayer videoUrl={video.url}/>
        ))
      ) : (
        <YoutubeLazyPlayer videoUrl="https://youtu.be/0zZR6-5J3-8"/>
      )}
    </div>

    <TkCarouselSimilarityProducts externalId={product?.externalId}/>

    <TkCarouselCartBasedProducts productId={product?._id}/>
  </div>
};

export default SmallScreen;