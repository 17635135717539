import React, {memo, useCallback, useState} from "react";
import {ITkProductModel} from "../../../models/product";
import TkProportionalImage from "../../particles/TkProportionalImage";
import "./style.scss";
import {formatMoney} from "../../../utils/number-utils";
import TkSvgIcon from "../../particles/TkSvgIcon";
import {useTkAppErrors, useTkCart, useTkListPro, useTkMedia, useTkUser,} from "../../../context/TkContext";
import {tk_route_item_added_to_cart} from "../../../views/TkItemAddedToCartView";
import {Link, useHistory} from "react-router-dom";
import TkSkeleton from "../../particles/TkSkeleton";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {F8F8F8_BASE64, vibrate} from "../../../utils/utils";
import {tk_route_manufacturer} from "../../../views/TkSearchView";
import TkModal, {TkModalActions, TkModalBody} from "../../particles/TkModal";
import {transformGraphQLErrors} from "../../../models/graphql";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg'

const Skeleton: React.FC = memo(() => {
  return (
    <div className="TkHorizontalCardProduct__container">
      <img className="img-responsive" src={F8F8F8_BASE64} alt="Blank" />
      <div className="TkHorizontalCardProduct__right">
        <TkSkeleton width="100%" height="20px" />
        <TkSkeleton width="80%" height="20px" />
        <TkSkeleton width="130px" height="20px" />
        <br />
        <TkSkeleton width="120px" height="15px" />
        <br />
        <TkSkeleton width="140px" height="15px" />

        <div className="m-t-15px">
          <TkSkeleton width="112px" height="31px" />
        </div>
      </div>
    </div>
  );
});

const TkHorizontalCardProduct: React.FC<{
  product: ITkProductModel;
  showSkeleton?: boolean;
}> = ({ product, showSkeleton = false }) => {
  const { addToCart } = useTkCart();
  const { isTypePro, isTypeBusiness } = useTkUser();
  const { setProduct } = useTkListPro();
  const { goToPageError } = useTkAppErrors();
  const history = useHistory();
  const [adding, setAdding] = useState(false);
  const [showAlertValidation, setShowAlertValidation] = useState<string>(null);
  const { isMediaXs } = useTkMedia();

  const add = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        setAdding(true);

        await addToCart(product, 1, false);
        history.push(tk_route_item_added_to_cart(product));
      } catch (e) {
        vibrate();
        const msgs = transformGraphQLErrors(e);
        setShowAlertValidation(
          msgs
            ? msgs.map((m) => m.message).join("\n")
            : "Falhou ao tentar adicionar ao carrinho. Tente novamente."
        );
      } finally {
        setAdding(false);
      }
    },
    [product, addToCart, goToPageError]
  );

  if (showSkeleton) return <Skeleton />;

  const { stock, minimumSaleQuantity, controlMultiplicity } = product;
  const inStock = stock && stock > 0 && (!controlMultiplicity || (controlMultiplicity && stock >= minimumSaleQuantity));

    return <>
        <article className='TkHorizontalCardProduct__container'>

            <TkProportionalImage url={!!product.thumbnail ? product.thumbnail : NoPhoto} alt={product.name} clickEvent={() => history.push(tk_route_product_detail(product))}/>
            <div className='TkHorizontalCardProduct__right'>
                <h1 onClick={() => history.push(tk_route_product_detail(product))}>{product.name}</h1>
                {<p className='TkHorizontalCardProduct__reference'>{product.manufacturerReference} {(product.manufacturerCode && product.manufacturerReference) && " | " } {product.manufacturerCode}</p>}                <span className='TkHorizontalCardProduct__brand'>por <Link
                    to={tk_route_manufacturer(product.manufacturerId)}
                    onClick={(e) => e.stopPropagation()}>{product.manufacturerName}</Link></span>
                <div className='TkHorizontalCardProduct__price'>{formatMoney(product.promoPrice)} <span
                    className='TkHorizontalCardProduct__unit'>por <span className='c-b'>{product.unitCommercialName ? product.unitCommercialName.toLocaleLowerCase() : 'unidade'}</span></span></div>
                <span>
                  {(inStock || product.leadTime) && product.price? (
                    inStock ? 
                        <p className="TkHorizontalCardProduct__availability">Em Estoque</p> 
                        : <p className="TkHorizontalCardProduct__backorder">Sob encomenda</p>
                    ) : (
                      <p className="TkHorizontalCardProduct__out-of-stock">Indisponível</p>
                    )
                  }
                </span>
                

                <div className='m-t-10px'>
                    <button type='button'
                            disabled={adding}
                            onClick={add}
                            className='button-2 d-inline m-r-1em'>
                        <TkSvgIcon
                            className={adding ? 'rotate-1-seg' : ''}
                            icon={adding ? 'sync-solid' : 'shopping-cart-solid'}
                        />Adicionar
                    </button>

                    {(isTypePro() || isTypeBusiness()) && <button type='button'
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setProduct(product)
                                            }}
                                            className='btn button-3 d-inline'>
                                                <TkSvgIcon
                                                    icon='material-playlist-add-check'
                                                    />
                                                    {!isMediaXs ? 
                                                     'PRO' : ''
                                                    }
                        
                    </button>}
                </div>
            </div>
        </article>
        <TkModal show={showAlertValidation !== null}
                 close={(e) => {
                     e.preventDefault()
                     e.stopPropagation()
                     setShowAlertValidation(null)
                 }}
                 title='Adicionar ao Carrinho'
                 size='sm'>
            <TkModalBody>
                {showAlertValidation}
            </TkModalBody>

            <TkModalActions>
                <button type='button'
                        className='btn btn-secondary'
                        onClick={(e: any) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowAlertValidation(null)
                        }}>Fechar
                </button>
            </TkModalActions>
        </TkModal>
    </>
};

export default TkHorizontalCardProduct;
