import React, {FC, memo, useCallback} from "react";
import {tk_route_root} from "../../../views/TkHomeView";
import {Link} from "react-router-dom";
import {isBlank} from "../../../utils/string-utils";
import './style.scss'

const TkLogoVector: FC<{
    className?: string
}> = ({className}) => {

    return <svg xmlns="http://www.w3.org/2000/svg"
                className={className}
                viewBox="0 0 259.61 103.47">
        <defs>
            <style>{".cls-1{fill:none;}.cls-2{clip - path:url(#clip-path);}.cls-3{fill:#fff;}"}</style>
            <clipPath id="clip-path" transform="translate(-28.35 -28.35)">
                <rect className="cls-1" width="316.3" height="160.16"/>
            </clipPath>
        </defs>
        <g className="cls-2">
            <path className="cls-3 te"
                  fill='currentColor'
                  d="M60.87,113.38V99.21H51V59.53h9.85V46.77H51V28.35H35.5V46.77H28.35V59.53H35.5v45.61c0,5.54,2.7,8.24,8.38,8.24Zm38.34-39.6H86.46V59.53H99.21Zm41.11-45.43h-15.6v85h15.6ZM114.81,70.87V55c0-5.55-2.84-8.25-8.52-8.25H79.38c-5.81,0-8.51,2.7-8.51,8.25v50.12c0,5.54,2.7,8.24,8.51,8.24h26.91c5.68,0,8.52-2.7,8.52-8.24V95h-12l-2.13,4.25H86.46V85l20.1,0c5.55,0,8.25-2.84,8.25-8.51Zm-15.6,2.91H86.46V59.53H99.21Zm15.6-2.91V55c0-5.55-2.84-8.25-8.52-8.25H79.38c-5.81,0-8.51,2.7-8.51,8.25v50.12c0,5.54,2.7,8.24,8.51,8.24h26.91c5.68,0,8.52-2.7,8.52-8.24V95h-12l-2.13,4.25H86.46V85l20.1,0c5.55,0,8.25-2.84,8.25-8.51Z"
                  transform="translate(-28.35 -28.35)"/>
            <polygon className="cls-3 half-k"
                     points="111.97 55.27 126.14 85.04 141.73 85.04 124.27 48.95 139.68 18.41 124.03 18.41 111.97 42.52 111.97 55.27"/>
            <polygon className="cls-3 y"
                     points="184.25 18.43 161.57 103.47 145.98 103.47 152.36 79.55 139.76 32.29 146.69 18.43 151.65 18.43 160.16 50.31 168.66 18.43 184.25 18.43"/>
            <path className="cls-3 com-br"
                  d="M160.16,33.41h-1.42v1.22h1.42Zm10.07.62V32.25h-1.8v1.2h-4.61V29.52h4.61v1.14h1.8V28.94c0-.36-.27-.59-.69-.59h-6.86c-.42,0-.67.23-.67.59V34a.59.59,0,0,0,.67.6h6.86c.42,0,.69-.23.69-.6m10.35,0V28.94a.59.59,0,0,0-.67-.59h-7c-.42,0-.67.23-.67.59V34a.59.59,0,0,0,.67.6h7a.59.59,0,0,0,.67-.6m-1.81-.58h-4.72V29.52h4.72Zm13.55,1.18V28.35h-2l-2.85,4.74h0l-2.84-4.74h-2v6.28h1.78V30.79l2.27,3.84h1.65l2.26-3.84v3.84Zm3.27-1.22h-1.42v1.22h1.42Zm9.93.62v-1.6c0-.28-.13-.41-.41-.52l-1.12-.5,1-.49a.54.54,0,0,0,.4-.54V28.94c0-.36-.26-.59-.68-.59h-7.33v6.28h7.4c.42,0,.69-.23.69-.6m-1.89-3.68-1.1.57h-3.29V29.47h4.39Zm.08,3.15h-4.47V31.89h3.29l1.18.56Zm12.17,1.13v-.06l-3.12-2.17h1.92c.41,0,.68-.22.68-.58V28.94a.6.6,0,0,0-.68-.59h-7.14v6.28h1.81V29.52h4.21v1.83h-3.35v.75l3.47,2.53Z"
                  transform="translate(-28.35 -28.35)"/>
            <path className="cls-3 line"
                  d="M244.45,37.52H157l-1.42,2.83h88.88v0a39.71,39.71,0,1,1-30,65.75,12.74,12.74,0,0,0-2.22,4.24,43.51,43.51,0,1,0,32.18-72.8"
                  transform="translate(-28.35 -28.35)"/>
            <path className="cls-3 symbol-1"
                  d="M271.48,80.08a12.63,12.63,0,0,1,4.81-9.93,33.28,33.28,0,0,0-7-12.32,12.67,12.67,0,0,1-17.43-10.3,33.37,33.37,0,0,0-14.88,0,12.68,12.68,0,0,1-17.44,10.3,33.26,33.26,0,0,0-7,12.31,12.65,12.65,0,0,1,0,19.85,33.31,33.31,0,0,0,6.95,12.25A12.68,12.68,0,0,1,237,112.54a33.32,33.32,0,0,0,14.87,0,12.68,12.68,0,0,1,17.44-10.3,33.15,33.15,0,0,0,7-12.24,12.64,12.64,0,0,1-4.8-9.92M246.15,94.22V86.91a7,7,0,0,1-3.41,0v7.31a14.29,14.29,0,0,1-12.48-12.48h7.31a7,7,0,0,1,0-3.41h-7.31a14.29,14.29,0,0,1,12.48-12.48v7.31a7,7,0,0,1,3.41,0V65.85a14.29,14.29,0,0,1,12.48,12.48h-7.31a7,7,0,0,1,0,3.41h7.31a14.29,14.29,0,0,1-12.48,12.48"
                  transform="translate(-28.35 -28.35)"/>
            <path className="cls-3 symbol-2"
                  visibility="hidden"
                  d="M252.42,69.27a8.55,8.55,0,0,1,3.25-6.72A22.61,22.61,0,0,0,251,54.22a8.58,8.58,0,0,1-11.8-7,22.48,22.48,0,0,0-5-.57,23.15,23.15,0,0,0-5,.56,8.57,8.57,0,0,1-11.79,7,22.63,22.63,0,0,0-4.72,8.33,8.55,8.55,0,0,1,0,13.42,22.57,22.57,0,0,0,4.7,8.29,8.57,8.57,0,0,1,11.79,7,22.67,22.67,0,0,0,10.06,0,8.58,8.58,0,0,1,11.8-7,22.61,22.61,0,0,0,4.7-8.28,8.56,8.56,0,0,1-3.24-6.71m-17.14,9.56V73.89a4.83,4.83,0,0,1-1.15.14,4.89,4.89,0,0,1-1.15-.14v4.94a9.66,9.66,0,0,1-8.44-8.44h5a4.48,4.48,0,0,1,0-2.3h-5A9.66,9.66,0,0,1,233,59.65v4.94a4.89,4.89,0,0,1,1.15-.14,4.83,4.83,0,0,1,1.15.14V59.65a9.64,9.64,0,0,1,8.44,8.44h-4.94a4.48,4.48,0,0,1,0,2.3h4.94a9.64,9.64,0,0,1-8.44,8.44"
                  transform="translate(-28.35 -28.35)"/>
        </g>
    </svg>
};

export enum LogoMode {
    one
}

export const TkLogoBuilder:FC<{
    to?: string
    title?: string
    mode?: LogoMode
}> = memo(({to = tk_route_root, title = 'Voltar à tela inicial', mode = LogoMode.one}) => {

    const getMode = useCallback(() => {
        const clazz = ['TkLogoVector']
        switch (mode) {
            case LogoMode.one:
                clazz.push('__mode-one')
                break
        }


        return clazz.join('')
    }, [])

    if (isBlank(to)) return <TkLogoVector className={getMode()}/>

    return <Link to={to} title={title}>
        <TkLogoVector className={getMode()}/>
    </Link>
})

export default memo(TkLogoVector);
