import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {formatPostalCode, isBlank} from "../../utils/string-utils";
import {useTkAppErrors, useTkLocalization, useTkUser} from "../../context/TkContext";
import {Address} from "../../models/user";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {City, State} from "../../models/localization";
import {isArray, vibrate} from "../../utils/utils";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import Cleave from "cleave.js";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {animated as a, useTrail} from "react-spring";
import {tk_route_account} from "../TkMyAccountView";
import {tk_route_my_addresses} from "../TkMyAddressesView";

const base = '/sua-conta/enderecos';

export const tk_route_address = (isNew = false, addressId?: string) => {
  if (!isBlank(addressId)) return `${base}/editar/${addressId}`;
  else if (!isNew) return `${base}/:modo/:id?`;

  return `${base}/novo`;
};


const TkAddressView: React.FC = () => {
  const params = useParams<any>();
  const history = useHistory();
  const loc = useLocation()
  const {updateAddress, getAddress} = useTkUser();
  const {searchPostalCode, getStates, getCities} = useTkLocalization();
  const {goToPageError} = useTkAppErrors();
  const [address, setAddress] = useState<Address>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [isSearchingPostalCode, setSearchingPostalCode] = useState<boolean>(false);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [states, setStates] = useState<State[]>([]);
  const [stateId, setStateId] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);
  const [cityId, setCityId] = useState<string>('');
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [currentPostalCode, setCurrentPostalCode] = useState();
  const [validationState, setValidationState] = useState<any>({
    hasError: false,
    errors: []
  })

  const [companyDocument, setCompanyDocument] = useState<string | null>(null);
  const [postalCodeFound, setPostalCodeFound] = useState<{
    [key: string]: boolean
  }>({});

  const postalRef = useRef(null);
  const {value: neighborhood, bind: bindNeighborhood, setValue: setNeighborhood} = useSimpleInput('');
  const {value: cei, bind: bindCei, setValue: setCei} = useSimpleInput('');
  const {value: location, bind: bindAddress, setValue: setLocation} = useSimpleInput('');
  const {value: addressNumber, bind: bindAddressNumber, setValue: setAddressNumber} = useSimpleInput('');
  const {value: addressNotes, bind: bindAddressNotes, setValue: setAddressNotes} = useSimpleInput('');

  const config = {mass: 5, tension: 2000, friction: 200}
  const trail = useTrail(validationState.errors.length, {
    config,
    opacity: validationState.hasError ? 1 : 0,
    from: {opacity: 0},
  });


  const changeState = useCallback(async (e: any) => {
    if (isBlank(e.target.value)) {
      setStateId(e.target.value);
      setCities([]);
      return
    }

    try {
      const id = e.target.value;

      if (stateId !== id && !loadingCities) {
        setStateId(id);
        setLoadingCities(true);
        const c = await getCities(id);
        setCities(c);
      }
    } catch (e) {
      goToPageError(e);
    }
    setLoadingCities(false);
  }, [stateId, loadingCities, setLoadingCities]);

  const onChangePostalCode = useCallback(async (e: any) => {
    const postalCode = e.target.value.replace(/\D+/gi, '');

    if (/\d{8}/.test(postalCode) && (!currentPostalCode || currentPostalCode.postalCode !== postalCode)) {
      setSearchingPostalCode(true)
      searchPostalCode(postalCode)
        .then(async (result: any) => {
          setCurrentPostalCode(result);
          setPostalCodeFound({
            ...postalCodeFound,
            [postalCode]: !!result
          })

          if (result) {

            await changeState({
              target: {
                value: result.stateId
              }
            });

            setStateId(result.stateId);
            setCityId(result.cityId);
            setLocation(result.locationType + ' ' + result.location);
            setNeighborhood(result.neighborhood || '');

          } else {
            setCityId(undefined);
            setStateId(undefined);
            setCities([]);
            vibrate();
          }
        })
        .catch(goToPageError)
        .finally(() => setSearchingPostalCode(false))
    }
  }, [currentPostalCode, setStateId, setCityId, setCities, setLocation, setCurrentPostalCode, setPostalCodeFound, postalCodeFound, setSearchingPostalCode]);

  useEffect(() => {
    const p = new URLSearchParams(loc.search)
    const doc = p.get("empresa")
    const from = p.get('from')

    if (!isBlank(from)) setIsDefault(true)

    if (!isBlank(doc)) setCompanyDocument(doc)

    if (!isBlank(params.id)) {
      setLoadingAddress(true)
      getAddress(params.id, !isBlank(doc) ? doc : null)
        .then(async result => {
          if (result) {
            setAddress(result);

            setStateId(result.state._id);
            setCityId(result.city._id);
            setNeighborhood(result.neighborhoodName);
            setLocation(result.location);
            setAddressNumber(result.locationNumber);
            setAddressNotes(result.locationNotes);
            setIsDefault(result.isDefault);

            if (!isBlank(doc) && !isBlank(result.cei)) {
              setCei(result.cei)
            }

            if (postalRef && postalRef.current) {
              postalRef.current.value = formatPostalCode(result.postalCode);
            }

            await changeState({
              target: {
                value: result.state._id
              }
            });
          }
        })
        .catch(goToPageError)
        .finally(() => setLoadingAddress(false))
    }

    if (states && states.length === 0) {
      getStates()
        .then(setStates)
        .catch(goToPageError);
    }


  }, [params, loc, setCompanyDocument, setIsDefault]);

  useEffect(() => {
    let postalCleave: Cleave | null;

    if (postalRef.current) {
      postalCleave = new Cleave(postalRef.current || '', {
        numericOnly: true,
        blocks: [5, 3],
        delimiters: ["-"],
        onValueChanged: ({target}) => {
          target.value.replace(/\D+/gi, '');
          setCurrentPostalCode(target.value)
        }
      });
    }

    return () => {
      if (postalCleave) postalCleave.destroy();
      postalCleave = null;
    }
  }, [postalRef])

  async function onSubmit(e: any) {
    e.preventDefault();

    let newState: { hasError: boolean; errors: any[] };
    newState = {
      hasError: false,
      errors: []
    };

    if (isBlank(postalRef.current.value)) {
      newState.hasError = true;
      newState.errors = ['Preencha o CEP'];
    } else if (postalRef.current.value.replace(/\D/gi, '').length !== 8) {
      newState.hasError = true;
      newState.errors = ['CEP inválido'];
    }

    if (isBlank(stateId + '')) {
      newState.hasError = true;
      newState.errors = [...newState.errors, 'Preencha o estado'];
    }

    if (isBlank(cityId + '')) {
      newState.hasError = true;
      newState.errors = [...newState.errors, 'Preencha a cidade'];
    }

    if (isBlank(neighborhood)) {
      newState.hasError = true;
      newState.errors = [...newState.errors, 'Preencha o bairro'];
    }

    if (isBlank(location)) {
      newState.hasError = true;
      newState.errors = [...newState.errors, 'Preencha o endereço'];
    }

    if (isBlank(addressNumber)) {
      newState.hasError = true;
      newState.errors = [...newState.errors, 'Preencha o número'];
    }

    let isSuccess = false

    setValidationState(newState);

    if (!newState.hasError) {
      try {
        setSaving(true);
        await updateAddress({
          postalCode: postalRef.current.value,
          stateId,
          cityId,
          location,
          locationNumber: addressNumber,
          locationNotes: addressNotes,
          neighborhoodName: neighborhood,
          cei,
          isDefault,
          _id: address._id
        }, companyDocument);

        const p = new URLSearchParams(loc.search)
        const from = p.get('from')
        if (from) setTimeout(() => history.replace(from), 1000)

        isSuccess = true

      } catch (e) {
        console.error('Falha salvamento endereço', e);

        if (isArray(e)) {
          newState.errors.length = 0;
          newState.hasError = true
          e.forEach((obj: any) => newState.errors.push(obj.message));
          setValidationState(newState);
        } else {
          newState.hasError = true
          newState.errors = ['Ops... Falha ao tentar salvar endereço.']
          setValidationState(newState)
        }
      } finally {
        setSaving(false);

        if (isSuccess) history.goBack();
      }
    }
  }

  function isUnKnowPostalCode() {
    const postalCode = (postalRef?.current?.value || '').replace(/\D+/g, '')

    if (isSearchingPostalCode || !isBlank(address?._id)) return false

    return /\d{8}/.test(postalCode) ? !postalCodeFound[postalCode] : false
  }

  return <>
    <TkHeadSEO title={(address && address._id ? 'Alterar' : 'Novo') + ' Endereço | Teky'}/>

    <TkHeader/>

    <div className="container m-t-1rem">
      <TkBreadcrumb list={[{
        url: tk_route_account,
        label: 'Sua Conta'
      }, {
        url: tk_route_my_addresses({document: companyDocument}),
        label: isBlank(companyDocument) ? 'Meus Endereços' : 'Endereços da Empresa'
      }]}/>
      <h1>{address && address._id ? 'Alterar Endereço' : 'Novo Endereço'}</h1>
    </div>

    <form className="container" noValidate onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-sm-3 col-md-2">
          <div className="form-group">
            <label htmlFor="addressEditPostalCode">CEP</label>
            <input type="text"
                   ref={postalRef}
                   placeholder='99999-999'
                   onKeyUp={onChangePostalCode}
                   onPaste={(e) => onChangePostalCode({target: {value: e.clipboardData.getData('Text')}})}
                   autoComplete='off'
                   id='addressEditPostalCode'
                   className='form-control form-control-sm t-a-c'/>
            {isUnKnowPostalCode()  && <small className='c-p-red'>CEP não encontrado</small>}
          </div>
        </div>
      </div>
      {!isBlank(companyDocument) && <div className="row">
          <div className="col-12 col-sm-4 col-md-3">
              <div className="form-group">
                  <label htmlFor="addressEditCei">CEI da obra</label>
                  <input type="text"
                         placeholder='Informe o CEI'
                         id='addressEditCei'
                         maxLength={30}
                         className='form-control form-control-sm' {...bindCei}/>
              </div>
          </div>
      </div>}
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3">
          <div className="form-group">
            <label htmlFor="addressEditState">Estado</label>
            <select id='addressEditState'
                    value={stateId}
                    onChange={changeState}
                    className='form-control form-control-sm'>
              <option value=''>Selecione o estado</option>
              {states && states.map((s, idx) => {
                return <option key={idx} value={s._id}>{s.acronym} - {s.name}</option>
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3">
          <div className="form-group">
            <label htmlFor="addressEditCity">Cidade</label>
            <select id='addressEditCity'
                    value={cityId}
                    onChange={(e: any) => setCityId(e.target.value)}
                    disabled={loadingCities}
                    className='form-control form-control-sm'>
              <option value=''>{loadingCities ? 'Carregando...' : 'Selecione a cidade'}</option>
              {cities && cities.map((c, idx) => {
                return <option key={idx} value={c._id}>{c.name}</option>
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3">
          <div className="form-group">
            <label htmlFor="addressEditNeighborhood">Bairro</label>
            <input type="text"
                   placeholder='Informe o bairro'
                   id='addressEditNeighborhood'
                   className='form-control form-control-sm' {...bindNeighborhood}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3">
          <div className="form-group">
            <label htmlFor="addressEditAddress">Endereço</label>
            <input type="text"
                   placeholder='Informe a rua'
                   id='addressEditAddress'
                   className='form-control form-control-sm' {...bindAddress}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-1 col-md-1">
          <div className="form-group">
            <label htmlFor="addressEditAddressNumber">Nº</label>
            <input type="text"
                   placeholder='Número'
                   id='addressEditAddressNumber'
                   className='form-control form-control-sm' {...bindAddressNumber}/>
          </div>
        </div>
        <div className="col-12 col-sm-2 col-md-2">
          <div className="form-group">
            <label htmlFor="addressEditAddressNotes">Complemento</label>
            <input type="text"
                   placeholder='Casa, apê, bloco'
                   id='addressEditAddressNotes'
                   className='form-control form-control-sm'
                   maxLength={40}{...bindAddressNotes}/>
          </div>
        </div>
      </div>
      {address && <div className="row">
          <div className="col-12 col-md-3">
              <div className="form-check">
                  <input className="form-check-input"
                         type="checkbox"
                         id='addressEditIsDefault'
                         checked={isDefault}
                         disabled={isDefault && !isBlank(address._id)}
                         onChange={() => setIsDefault(v => !v)}/>
                  <label className="form-check-label u-s-n"
                         htmlFor="addressEditIsDefault">Endereço padrão?</label>
              </div>
          </div>
      </div>}
      <div className="row">
        <div className="col-12 col-md-3">
          {trail.map((props, index) => (
            <a.div
              key={index}
              className='alert alert-danger'
              style={props}>
              {validationState.errors[index]}
            </a.div>
          ))}
        </div>
      </div>
      <div className="row m-t-40px">
        <div className="col-12 col-md-3 t-a-c">
          <button type='button'
                  disabled={saving || loadingAddress}
                  className='btn btn-secondary m-r-1em'
                  onClick={() => history.goBack()}>
            <TkSvgIcon icon='chevron-left-solid' className='m-r-1em'/>
            Voltar
          </button>
          <button type='submit'
                  disabled={saving || loadingAddress}
                  className='btn btn-primary m-l-1em'>
            {saving ? 'Salvando...' : 'Salvar'}
            {saving ? <TkSvgIcon icon='sync-solid' className='rotate-1-seg m-l-1em'/> :
              <TkSvgIcon icon='check-solid' className='m-l-1em'/>}
          </button>
        </div>
      </div>
    </form>

    <TkNewsLetter/>
    <TkFooter/>
  </>
};

export default TkAddressView
