import React, {FC, memo, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {tk_route_company} from "../TkMyCompanyView";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {tk_route_root} from "../TkHomeView";
import {useTkUser} from "../../context/TkContext";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkDivider from "../../components/particles/TkDivider";
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView";
import {formatDocument} from "../../utils/string-utils";

const _SuccessSignUp: FC = () => {
    const history = useHistory()
    const {search} = useLocation()
    const {me, isAuth} = useTkUser()
    const [isCompany, setCompany] = useState(false)
    const [isMigrate, setMigrate] = useState(false)

    useEffect(() => {
        const p = new URLSearchParams(search)
        setCompany(p.get('tipo') === 'business')
        setMigrate(p.get('estagio') === 'conversao-sucesso')

        const from = p.get('from')
      //  alert (from);
        
        if (from !== null){
            const t = setTimeout(() => history.replace(from), 3000)
            return () => clearTimeout(t)
        }
        
    }, [search, setCompany])


    useEffect(() => {
        if (isMigrate && isAuth()) {
            (async () => {
                await me()
            })()
        }
    }, [me, isAuth, isMigrate])

    return <>

        <TkHeadSEO title='Criação de Conta | Teky'/>

        <div className="container m-t-40px">
            <TkLogoBuilder/>
            <h1>{isMigrate ? 'Seja bem-vindo!' : 'Cadastro feito com sucesso!'}</h1>

            <div className='TkSignUpView__success-container'>

                <div className='alert alert-success t-a-c'>{isMigrate ? 'Migração de conta realizada' : 'Cadastro realizado'} com sucesso. Bem-vindo a Teky!</div>

                {isCompany && <div className='m-t-40px'>
                    <p className='t-a-c'><b>Vimos que você efetuou um cadastro como Empresa. Caso ainda não tenha adicionado uma empresa, inclua os dados da mesma para efetuar compras no site.</b></p>
                    <a href={tk_route_company(true)} className='btn btn-block btn-primary t-a-c m-t-40px'>
                        Adicionar novas empresas
                        <TkSvgIcon icon='chevron-right-solid' className='m-l-1em'/>
                    </a>
                </div>}

                <a href={tk_route_root} className='btn btn-block btn-primary t-a-c m-t-40px' title='Ir para home'>
                    Ir para home
                </a>
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>
}

export default memo(_SuccessSignUp)