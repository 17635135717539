import React, {FC, lazy, memo, Suspense} from "react";
import './style.scss'
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkFooter from "../../components/particles/TkFooter";

const ShowList = lazy(() => import('./_showList'))

export const tk_route_receive_list = '/lista-compartilhada';

export const tk_route_share_list = (listId: string, analyticsSource: string, analyticsCampaign: string, analyticsMedium: string) => {
    const p = new URLSearchParams();
    p.set('fromList', listId)
    p.set('utm_source', analyticsSource)
    p.set('utm_medium', analyticsMedium)
    p.set('utm_campaign', analyticsCampaign)

    const {location: {protocol, host}} = document

    return `${protocol}//${host}${tk_route_receive_list}?${p.toString()}`
}

const TkReceiveListView: FC = () => {

    return <>
        <TkHeadSEO title='Lista Compartilhada | Teky'
                   description='Lista de produtos compartilhada com você'/>

           <Suspense fallback={<div>Carregando...</div>}>
               <ShowList/>
           </Suspense>

        <TkFooter/>
    </>
}

export default memo(TkReceiveListView)