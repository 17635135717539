import React, {FC, memo, useEffect} from "react";

import {useParams} from "react-router-dom";
import {useTkMedia, useTkRecommender} from "../../../context/TkContext";
import TkCardProductSlider from "../../product/TkCardProductSlider";
import TkCarousel from "../../product/TkCarousel";

const TkComplementCart:FC<{
  title?: string
  quantity?: number
}> = ({title = 'Quem comprou, comprou também', quantity= 25}) => {
  const {id} = useParams()
  const {isMediaXs, isMediaSm} = useTkMedia()
  const {complementRecommender, loadComplementRecommender, isLoadingComplementRecommender} = useTkRecommender()

  useEffect(() => {
    loadComplementRecommender(quantity)
  }, [id])

  return <>
    <h3 className='TkItemAddedToCart__title-related t-a-c'>{title}</h3>

    {(!isMediaXs && !isMediaSm) ?
      <div className="container m-t-1rem">
        <TkCarousel.Root>
          <div className='TkCarousel__padding'>
                <TkCarousel.Product products={complementRecommender} isShowSkeleton={isLoadingComplementRecommender} />
            </div>
        </TkCarousel.Root>
      </div>
      :
      (<TkCardProductSlider products={complementRecommender ?? []}
                            isLoadingProducts={isLoadingComplementRecommender}/>)}


  </>
}

export default memo(TkComplementCart)
