import React, {useCallback} from "react";
import "./style.scss";
import {useTkCart, useTkDrawer, useTkProduct, useTkUser, useTkWhatsAppBalloon,} from "../../../context/TkContext";
import {Link, useHistory} from "react-router-dom";
import {tk_route_account} from "../../../views/TkMyAccountView";
import {tk_route_sign_in} from "../../../views/TkSignInView";
import {tk_route_my_orders} from "../../../views/TkMyOrdersView";
import {tk_route_root} from "../../../views/TkHomeView";
import {tk_route_pro} from "../../../views/TkProView";
import {tk_route_division} from "../../../views/TkSearchView";
import {tk_route_about} from "../../../views/TkAboutView";
import {tk_route_exchanges_and_devolution} from "../../../views/TkExchangesAndDevolutionView";
import TkSvgIcon from "../TkSvgIcon";
import {tk_route_my_lists} from "../../../views/TkMyListsView";

const TkDrawer: React.FC = () => {
  const { isShow, setIsShow } = useTkDrawer();
  const {setShowIcon} = useTkWhatsAppBalloon()
  const { isAuth, logout, isTypePro, isTypeBusiness } = useTkUser();
  const { clearCart } = useTkCart();
  const {
    state: { divisions },
  } = useTkProduct();
  const history = useHistory();

  const doLogout = useCallback(async () => {
    await logout();
    clearCart();
    history.push(tk_route_root);
  }, [logout, history]);

  if (!divisions || divisions.length === 0) return <></>;

  return (
    <>
      {isShow && (
        <>
          <div className="TkDrawer__overlay" onClick={() => {
            setIsShow(false)
            setShowIcon(true)
          }} />
        </>
      )}

      <nav className={`TkDrawer ${isShow ? "TkDrawer--active" : ""}`}>
        <ul>
          <li className="m-t-10px">
            <Link
              to={isAuth() ? tk_route_account : tk_route_sign_in}
              onClick={() => setIsShow(false)}
            >
              <TkSvgIcon icon="user-circle-solid" />
              <span>Conta</span>
            </Link>
          </li>
          <li>
            <Link to={tk_route_my_orders} onClick={() => setIsShow(false)}>
              <TkSvgIcon icon="shopping-basket-solid" />
              <span>Pedidos</span>
            </Link>
          </li>
          {isAuth() && (isTypePro() || isTypeBusiness()) && (
            <li>
              <Link
                to={tk_route_my_lists}
                title="Minhas listas pro"
                onClick={() => setIsShow(false)}
              >
                <TkSvgIcon icon="list-alt-regular" />
                <span>Lista PRO</span>
              </Link>
            </li>
          )}
          <li className="TkDrawer__divider" />
          <li className="TkDrawer__title">COMPRAR POR CATEGORIA</li>

          {divisions.map((d, idx) => {
            return (
              <li key={idx}>
                <Link
                  to={tk_route_division(d)}
                  onClick={() => setIsShow(false)}
                >
                  <TkSvgIcon icon={d.icon} />
                  <span>{d.name}</span>
                </Link>
              </li>
            );
          })}

          <li className="TkDrawer__divider" />
          <li>
            <Link to={tk_route_about} onClick={() => setIsShow(false)}>
              <span />
              <span>Quem Somos</span>
            </Link>
          </li>

          <li>
            <Link to={tk_route_pro} onClick={() => setIsShow(false)}>
              <span />
              <span>Teky Pro</span>
            </Link>
          </li>

          <li className="TkDrawer__divider" />

          <li className="TkDrawer__title">AJUDA E CONFIGURAÇÕES</li>

          <li>
            <Link to={tk_route_account} onClick={() => setIsShow(false)}>
              <span />
              <span>Sua conta</span>
            </Link>
          </li>
          <li>
            <Link
              to={tk_route_exchanges_and_devolution}
              onClick={() => setIsShow(false)}
            >
              <span />
              <span>Trocas e Devoluções</span>
            </Link>
          </li>

          {isAuth() && (
            <li>
              <a
                href="#logout"
                onClick={() => {
                  doLogout();
                  setIsShow(false);
                }}
              >
                <TkSvgIcon icon="sign-out-alt-solid" />
                Sair
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default TkDrawer;
