import React, {createContext, useCallback, useContext} from "react";
import TkMediaContext, {TkMediaContextType} from "./TkMediaContext";
import TkDrawerContext, {TkDrawerContextType} from "./TkDrawerContext";
import TkBreadcrumbContext, {TkBreadcrumbContextType,} from "./TkBreadCrumbContext";
import TkUserContext, {TkUserContextType} from "./TkUserContext";
import TkCartContext, {TkCartContextType} from "./TkCartContext";
import TkOrderContext, {TkOrderContextType} from "./TkOrderContext";
import TkProductContext, {TkProductContextType} from "./TkProductContext";
import TkAppErrorsContext, {TkAppErrorsContextType} from "./TkAppErrors";
import TkNetworkStatusContext, {TkNetworkStatusContextType,} from "./TkNetworkStatusContext";
import {httpPostGraphQL} from "../utils/http-utils";
import {sendBeInTouchMutation} from "./queries";
import TkLocalizationContext, {TkLocalizationContextType,} from "./TkLocalizationContext";
import TkNewsletterContext, {TkNewsletterContextType,} from "./TkNewsletterContext";
import TkListProContext, {TkListProContextType} from "./TkListProContext";
import TkShipmentContext, {TkShipmentContextType} from "./TkShipmentContext";
import {TkWhatsappBalloonContext, TkWhatsappBalloonContextType} from "./TkWhatsappBalloonContext";
import TkCheckoutContextContext, {TkCheckoutContextType} from "./TkCheckoutContext";
import {TkRecommenderContext, TkRecommenderContextType} from "./TkRecommenderContext";

export interface TkContext {
    media: TkMediaContextType;
    drawer: TkDrawerContextType;
    user: TkUserContextType;
    cart: TkCartContextType;
    breadcrumb: TkBreadcrumbContextType;
    order: TkOrderContextType;
    product: TkProductContextType;
    appErrors: TkAppErrorsContextType;
    networkStatus: TkNetworkStatusContextType;
    localization: TkLocalizationContextType;
    newsletter: TkNewsletterContextType;
    listPro: TkListProContextType;
    shipment: TkShipmentContextType;
    whatsApp: TkWhatsappBalloonContextType
    checkout: TkCheckoutContextType
    recommender: TkRecommenderContextType
    sendBeInTouch: (
        name: string,
        subject: string,
        message: string,
        phone?: string,
        email?: string
    ) => Promise<boolean>;
}

export const TkContext = createContext<TkContext>({
    media: {},
    drawer: {},
    user: {},
    cart: {},
    breadcrumb: {},
    order: {},
    product: {},
    appErrors: {},
    localization: {},
    networkStatus: {},
    newsletter: {},
    shipment: {},
    listPro: {},
    whatsApp: {},
    checkout: {},
    recommender: {},
} as TkContext);

const TkProvider: React.FC = ({children}) => {
    const sendBeInTouch = useCallback(
        async (
            name: string,
            subject: string,
            message: string,
            phone?: string,
            email?: string
        ): Promise<boolean> => {
            try {
                const {data: result} = await httpPostGraphQL({
                    query: sendBeInTouchMutation,
                    variables: {
                        name,
                        subject,
                        message,
                        phone,
                        email,
                    },
                });

                return Promise.resolve(result.data.sendBeInTouch);
            } catch (e) {
                console.error("Falhou no envio de contato", e);

                return Promise.reject(e);
            }
        },
        []
    );

    return <TkContext.Provider
        value={{
            media: TkMediaContext(),
            drawer: TkDrawerContext(),
            user: TkUserContext(),
            cart: TkCartContext(),
            breadcrumb: TkBreadcrumbContext(),
            order: TkOrderContext(),
            product: TkProductContext(),
            appErrors: TkAppErrorsContext(),
            localization: TkLocalizationContext(),
            networkStatus: TkNetworkStatusContext(),
            newsletter: TkNewsletterContext(),
            listPro: TkListProContext(),
            shipment: TkShipmentContext(),
            whatsApp: TkWhatsappBalloonContext(),
            checkout: TkCheckoutContextContext(),
            recommender: TkRecommenderContext(),
            sendBeInTouch,
        }}
    >{children}</TkContext.Provider>
};

export const useTkMedia = () => useContext(TkContext).media;
export const useTkDrawer = () => useContext(TkContext).drawer;
export const useTkUser = () => useContext(TkContext).user;
export const useTkCart = () => useContext(TkContext).cart;
export const useTkOrder = () => useContext(TkContext).order;
export const useTkProduct = () => useContext(TkContext).product;
export const useTkBreadcrumb = () => useContext(TkContext).breadcrumb;
export const useTkAppErrors = () => useContext(TkContext).appErrors;
export const useTkNetworkStatus = () => useContext(TkContext).networkStatus;
export const useTkLocalization = () => useContext(TkContext).localization;
export const useTkNewsletter = () => useContext(TkContext).newsletter;
export const useTkListPro = () => useContext(TkContext).listPro;
export const useTkShipment = () => useContext(TkContext).shipment;
export const useTkWhatsAppBalloon = () => useContext(TkContext).whatsApp;
export const useTkCheckout = (): TkCheckoutContextType => useContext(TkContext).checkout;
export const useTkRecommender = (): TkRecommenderContextType => useContext(TkContext).recommender;

export default TkProvider;
