import React, {useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import './style.scss'
import {Link, useHistory} from "react-router-dom";
import {tk_route_sign_up} from "../TkSignUpView";
import TkCopyright from "../../components/particles/TkCopyright";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {animated as a, useTrail} from "react-spring";
import {isEmail} from "../../utils/string-utils";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {tk_route_sign_in} from "../TkSignInView";

export const tk_route_forget_pass = '/esqueci-a-senha';

interface StateForgotPassword {
    errors: string[]
    hasErrors: boolean
    doing: boolean
    success: boolean
}

const TkForgetPasswordView: React.FC = () => {
    const {forgotPassword} = useTkUser()
    const {goToPageError} = useTkAppErrors()
    const history = useHistory()
    const {value: email, bind: bindEmail} = useSimpleInput('')

    const initialState: StateForgotPassword = {
        hasErrors: false,
        errors: [],
        doing: false,
        success: false,
    };

    const [state, setState] = useState<StateForgotPassword>({...initialState});

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(state.errors.length, {
        config,
        opacity: state.hasErrors ? 1 : 0,
        from: {opacity: 0},
    });

    function onSubmit(e:any) {
        e.preventDefault();

        let newState = {...initialState};

        if (!isEmail(email)) {
            newState = {
                ...newState,
                hasErrors: true,
                errors: [...newState.errors, 'E-mail inválido']
            }
        }

        setState(newState);

        if (!newState.hasErrors) {
            setState({
                ...newState,
                doing: true
            });

            forgotPassword(email)
                .then(() => {
                    newState = {
                        ...newState,
                        hasErrors: false,
                        errors: [],
                        success: true
                    };

                    setTimeout(() => history.push(tk_route_sign_in), 3000);
                })
                .catch(goToPageError)
                .finally(() => {
                    setState({
                        ...newState,
                        doing: false
                    })
                });
        }
    }

    return <div className='TkForgetPassView'>
        <TkHeadSEO title='Esqueci a senha | Teky'/>

        <div className="container m-t-1rem">
            <div className='t-a-c'>
                <TkLogoBuilder/>
            </div>
            <p className='TkForgetPassView__tips t-a-c'>Informe os dados abaixo:</p>

            <br/>
            <form className='TkForgetPassView__form' onSubmit={onSubmit} noValidate>
                <div className="form-group">
                    <label htmlFor='userForgotEmail'>Informe o seu e-mail</label>
                    <input id='userForgotEmail'
                           type='email'
                           placeholder='E-mail'
                           className='form-control'
                           autoComplete='email'
                           {...bindEmail} required/>
                </div>

                <div className='TkForgetPassView__form-msgs'>
                    {trail.map((props, index) => (
                        <a.div
                            key={index}
                            className='alert alert-danger'
                            style={props}>
                            {state.errors[index]}
                        </a.div>
                    ))}

                    {state.success  &&
                    <div className='alert alert-success'>Caso o e-mail informado exista, você receberá uma mensagem com o link para trocar a senha.</div>}
                </div>

                <br/>
                <button type='submit'
                        disabled={state.doing || state.success}
                        className='btn btn-primary btn-block'>
                    {state.doing || state.success ? <TkSvgIcon className='rotate-1-seg' icon='sync-solid'/> :
                        <span>RECUPERAR SENHA</span>}
                </button>

                <div className='form-group t-a-r m-t-1rem TkForgetPassView__sign-up'>Não possui cadastro? <Link
                    to={tk_route_sign_up} title='Faça seu cadastro'>Faça seu cadastro</Link></div>
                <br/>
                <div className='form-group t-a-c '>
                    <TkCopyright/>
                </div>
            </form>
        </div>
    </div>
};

export default TkForgetPasswordView
