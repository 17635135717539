import React, {useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {Link} from "react-router-dom";
import {tk_route_root} from "../TkHomeView";
import {smoothScroll} from "../../utils/utils";
import TkSvgIcon from "../../components/particles/TkSvgIcon";

export const tk_route_exchanges_and_devolution = '/politica-de-trocas-e-devolucoes';

const TkExchangesAndEvolutionsView: React.FC = () => {

    useEffect(() => smoothScroll(), [])

    return <>
        <TkHeadSEO title='Política de Trocas e Devoluções | Teky'
                   description='Saiba como funciona as trocas e devoluções de produtos em nosso site'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_exchanges_and_devolution,
                    label: 'Trocas e Devoluções de Terceiros'
                }
            ]}/>

            <h1>1 - Trocas e Devoluções de Terceiros</h1>
            <p>1.1 Nenhum dos produtos em nossa plataforma é de nossa propriedade.</p>
            <p>1.2 Quando você faz um pedido cujo produto é anunciado, vendido e enviado diretamente por um vendedor terceiro, 
                sua devolução deve ser enviada para tal vendedor. Você pode solicitar a devolução diretamente para a Teky, 
                através do email <b>devolucoes@teky.com.br</b>, ou diretamente para o vendedor, o qual responderá com as 
                instruções para devolução.</p>

            <h1>2 - A Devolução</h1>
            <p>2.1 Todos os vendedores terceiros devem aceitar uma devolução solicitada em até
                7 dias após a entrega do produto, tendo em vista o direito de arrependimento do
                consumidor previsto em lei. Além disso, produtos caracterizados como bens
                duráveis com defeito podem ser devolvidos dentro do prazo de garantia legal de 90
                dias depois da entrega.</p>
            <p>2.2 Todos os produtos, de qualquer valor, devem ser devolvidos para o vendedor
                terceiro usando um serviço de entregas com rastreamento, como o PAC dos
                Correios. Produtos com valor de R$300,00 ou mais, além do rastreamento, devem
                ser devolvidos para o vendedor terceiro usando um serviço que ofereça seguro do
                valor dos produtos e aviso de recebimento com assinatura.</p>
            <p>2.3 Optando pela devolução será feito o ressarcimento será efetuado conforme as
                condições efetuadas no momento da compra.</p>
            <p>2.3.1 - Pagamento em cartão de crédito: O prazo de estorno é exclusivo da
                operadora do seu cartão de crédito, e podem ocorrer em até 2 (duas) faturas
                posteriores a compra.</p>
            <p>2.3.2 - Pagamento em boleto bancário ou Pix: O pagamento será reembolsado em
                até 2 dias úteis após o produto ser devolvido ao fornecedor.</p>
            <p>O pagamento será feito ao titular da conta pelo CPF ou CNPJ.</p>
            <br/>

            <Link to={tk_route_root} className='with-icon'><TkSvgIcon icon='chevron-left-solid' className='m-r-1em'/>Voltar
                à página inicial</Link>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkExchangesAndEvolutionsView
