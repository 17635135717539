import React from "react";
import {useTkMedia, useTkUser,} from "../../context/TkContext";
import {animated as a, useSpring} from "react-spring";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {Link} from "react-router-dom";
import {tk_route_sign_in} from "../TkSignInView";
import {tk_route_sign_up} from "../TkSignUpView";
import TkLogoVector from "../../components/particles/TkLogoVector";
import TkDots from "../../components/particles/TkDots";
import {tk_route_pro} from "../TkProView";
import {getS3Object} from "../../utils/http-utils";
import TkCarouselBestProducts from "../../components/product/TkCarouselBestProducts";
import TkCarouselUserBasedProducts from "../../components/product/TkCarouselUserBasedProducts";
import TkCarouselBestManufacturers from "../../components/product/TkCarouselBestManufacturers";
import TkCarouselBestCategories from "../../components/product/TkCarouselBestCategories";
import TkCarouselBestClasses from "../../components/product/TkCarouselBestClasses";
import TkCarouselOtherProducts from "../../components/product/TkCarouselOtherProducts";


const LoginMobile: React.FC = () => {
  const {isMediaXs} = useTkMedia();
  const {isAuth} = useTkUser();

  const effect = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  if (isMediaXs) {
    const banner = (
      <TkLazyImage
        src="https://teky.s3.sa-east-1.amazonaws.com/banners/toda_linha_de_produtos_tecnicos_teky_mobile.webp"
        alt="Banner Mobile Teky"
        className={`img-responsive ${isAuth() ? "" : "m-t-15px"}`}
      />
    );

    const title = <h1 className="carousel-title">Destaques do mês</h1>;

    if (isAuth())
      return <>
        <a.div style={effect}>{banner}</a.div>
        {title}
        <TkCarouselBestProducts/>
        <TkCarouselUserBasedProducts/>
      </>

    return <>
      <a.div className="container" style={effect}>
        <div className="row">
          <div className="col-12 TkHomeView__mobile-login">
            <p className="TkHomeView__mobile-login-msg">
              Acesse uma conta para ter a melhor experiência
            </p>
            <Link
              to={tk_route_sign_in}
              className="button-5"
              title="Fazer login no site"
            >
              Fazer login
            </Link>
            <Link
              to={tk_route_sign_up}
              className="button-6"
              title="Efetue seu cadastro"
            >
              Cadastre-se
            </Link>
          </div>
        </div>
      </a.div>
      {banner}
      {title}
    </>

  }

  return <></>;
};

const SmallScreen: React.FC = () => {
  const {isAuth} = useTkUser();

  return <>
    <LoginMobile/>


    {!isAuth() && <TkCarouselBestProducts/>}    

    <TkCarouselBestManufacturers/>

    <TkCarouselBestCategories/>

    <TkCarouselBestClasses/>

    <TkCarouselOtherProducts/>

    <h2 className="logo black">
      Descubra na <TkLogoVector className='m-l-1em m-r-1em'/>
    </h2>

    <div className="container">
      <div className="row">
        <div className="col-12">
          <Link to={tk_route_pro}>
            <TkLazyImage
                src={getS3Object("/banner-pro-mobile.webp")}
                alt="Descubra na Plenobras"
                className="img-responsive"
            />
          </Link>
          <br/>
          <br/>
          <TkDots count={1} active={0}/>
        </div>
      </div>
    </div>
  </>
};

export default SmallScreen;
