import React, {FC, memo, useCallback, useRef, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "./style.scss";
import TkSvgIcon from "../../particles/TkSvgIcon";
import TkSkeleton from "../../particles/TkSkeleton";
import {ITkProductModel} from "../../../models/product";
import {formatMoney} from "../../../utils/number-utils";
import TkProportionalImage from "../../particles/TkProportionalImage";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {useTkCart} from "../../../context/TkContext";
import {tk_route_item_added_to_cart} from "../../../views/TkItemAddedToCartView";
import LoadingImage from "../../../assets/images/tail-spin.svg";
import {tk_route_manufacturer} from "../../../views/TkSearchView";
import {vibrate} from "../../../utils/utils";
import {transformGraphQLErrors} from "../../../models/graphql";

const NoPhoto = "https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg";

const ImageSkeleton: React.FC = memo(() => (
  <div className="TkCardProduct__img" style={{padding: "10%"}}>
    <img
      src={LoadingImage}
      alt="Carregando imagem"
      className="img-responsive"
    />
  </div>
));

const CardProductSkeleton: FC = memo(() => {
  const cardRef = useRef(null);

  return (
    <div ref={cardRef} className="TkCardProduct TkCardProduct--no-click">
      <ImageSkeleton/>
      <div className="TkCardProduct__title">
        <TkSkeleton height={"1em"}/>
        <TkSkeleton height={"1em"}/>
        <TkSkeleton height={"1em"} width={"50%"}/>
      </div>
      <div className="TkCardProduct__brand">
        <TkSkeleton height="1em" width="30%"/>
      </div>
      <div className="TkCardProduct__price">
        <div className="TkCardProduct__promo-price">
          <TkSkeleton height="1em" width="30%"/>
        </div>
        <div className="TkCardProduct__default-price">
          <TkSkeleton height="1em" width="20%"/>
        </div>
      </div>
      <div className="TkCardProduct__stars">
        <TkSkeleton height="1em" width="80%"/>
      </div>
    </div>
  );
});

const AlertCard:FC<{
  close: (e: any) => void
  message: string
}> = ({close, message}) => {

  return <div className='TkCardProduct__overlay'>
    <div className='TkCardProduct__overlay-container'>
      <header className='TkCardProduct__overlay-header'>
        <span className='TkCardProduct__overlay-title'>Adicionar ao carrinho</span>
        <span className='TkCardProduct__overlay-close' onClick={close}>&times;</span>
      </header>

      <div className='TkCardProduct__overlay-body'>{message}</div>

      <div className='TkCardProduct__overlay-actions'>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={close}
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
}

const TkCardProduct: React.FC<{
  product?: ITkProductModel
  className?: string
  showAddToCart?: boolean
  showSkeleton?: boolean
  showBorders?: boolean
}> = ({
        product,
        className = "",
        showAddToCart = true,
        showBorders = true,
        showSkeleton = false,
      }) => {
  const cardRef = useRef(null);
  const imageUrl = product && product.thumbnail ? product.thumbnail : NoPhoto;
  const history = useHistory();
  const {addToCart} = useTkCart();
  const [addingToCart, setAddingToCart] = useState(false);
  const [showAlertValidation, setShowAlertValidation] = useState<string>(null);

  const add = useCallback(
    async (e: any) => {
      setAddingToCart(true);
      e.preventDefault();
      e.stopPropagation();

      if (product) {
        try {
          await addToCart(product, 1, false);
          history.push(tk_route_item_added_to_cart(product));
        } catch (e) {
          console.error('Falha ao adicionar ao carrinho', e)
          vibrate();
          const msgs = transformGraphQLErrors(e);
          setShowAlertValidation(
            msgs
              ? msgs.map((m) => m.message).join("\n")
              : "Falhou ao tentar adicionar ao carrinho. Tente novamente."
          );
        } finally {
          setAddingToCart(false);
        }
      }
    },
    [
      product,
      history,
      addToCart,
      setAddingToCart,
      addingToCart,
      setShowAlertValidation,
    ]
  );

  const goTo = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(tk_route_product_detail(product));
    },
    [product, history]
  );

  if (showSkeleton || !product) return <CardProductSkeleton/>;

  const {name, manufacturerName, manufacturerId, promoPrice, price, stock, minimumSaleQuantity, controlMultiplicity, leadTime} = product;

  const inStock = stock && stock > 0 && (!controlMultiplicity || (controlMultiplicity && stock >= minimumSaleQuantity));

  return <article
    ref={cardRef}
    className={`TkCardProduct ${className} ${
      showBorders ? "" : "TkCardProduct--no-borders"
    }`}
  >
    <TkProportionalImage
      url={imageUrl}
      alt={name}
      className="TkCardProduct__img"
      plainB={NoPhoto}
      clickEvent={goTo}
    />
    <h1 className="TkCardProduct__title" onClick={goTo}>{name}</h1>
    <span className="TkCardProduct__brand">
        por&nbsp;
      <Link
        to={tk_route_manufacturer(manufacturerId)}
        onClick={(e) => e.stopPropagation()}
      >
          {manufacturerName}
        </Link>
      </span>
    <div className="TkCardProduct__price">
      <div className="TkCardProduct__promo-price">
        {formatMoney(promoPrice)}
      </div>
      <div className="TkCardProduct__default-price">
        {formatMoney(price)}
      </div>
    </div>
    <div className="TkCardProduct__availability">
      {inStock ? <span className="TkCardProduct__available">Em estoque</span> : leadTime && leadTime > 0 ? <span className="TkCardProduct__backorder">Sob Encomenda</span> : <span className="TkCardProduct__out-of-stock">Indisponível</span> }
    </div>
    <div className="TkCardProduct__action">
      {showAddToCart && (
        <button
          className="TkCardProduct__add-to-cart"
          disabled={addingToCart}
          onClick={add}
          type="button"
        >
          <TkSvgIcon
            className={addingToCart ? "rotate-1-seg" : ""}
            icon={addingToCart ? "sync-solid" : "shopping-cart-solid"}
          />
          <span>{addingToCart ? "Aguarde..." : "Adicionar"}</span>
        </button>
      )}
    </div>

    {showAlertValidation !== null ?
      <AlertCard close={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowAlertValidation(null);
      }} message={showAlertValidation}/>
    :
      null
    }
  </article>
};

export default memo(TkCardProduct);
