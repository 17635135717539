import React, {useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {smoothScroll} from "../../utils/utils";

export const tk_route_about = "/quem-somos";

const TkAboutView: React.FC = () => {
  useEffect(() => smoothScroll(), []);

  return (
    <>
      <TkHeadSEO
        title="Quem Somos | Teky"
        description="Saiba mais sobre a Teky, o que fazemos, qual a nossa missão e valores"
      />

      <TkHeader />

      <TkDivisionsTop />

      <div className="container m-t-1rem">
        <TkBreadcrumb
          list={[
            {
              url: tk_route_about,
              label: "Quem somos",
            },
          ]}
        />

        <h1>Quem Somos</h1>
        <div className="row">
          <div className="col-lg-4">
            <TkLazyImage
              src="https://teky.s3-sa-east-1.amazonaws.com/homens-fixando-trilhos.png"
              className="img-responsive"
              alt="Foto homens fixando trilhos"
            />
          </div>
          <div className="col-lg-8">
            <p>
              <strong>
                A Teky é o lugar para descobrir, comprar e vender produtos
                técnicos dentro das linhas de Construção, Manutenção, Reparo e
                Operações.
              </strong>
            </p>
            <p>
              <strong>
                Somos uma Plataforma Omnichannel que conecta vendedores e
                compradores do mercado b2b.&nbsp;
              </strong>
            </p>

            <p>
              Nosso propósito é usar a tecnologia para conectar pessoas e
              profissionais que trabalham em setores técnicos com empresas que
              fornecem produtos de qualidade e preço justo. Dessa forma,
              contribuímos para viabilizar projetos e empreendimentos que
              transformam o mundo.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="offset-lg-4 col-lg-8 d-flex a-i-c j-c-c">
            <iframe
              width="560"
              height="315"
              className="m-t-30px"
              src="https://www.youtube.com/embed/0zZR6-5J3-8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkAboutView;
