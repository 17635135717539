import React, {useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import "./style.scss";

export const tk_route_seller = `/vendedor/plenobras`

  const TkSellerInfoView: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return <>
        <TkHeadSEO title='Plenobras | Teky'
                   description='Perfil do Vendedor: Plenobras'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container">
            <TkBreadcrumb list={[
                {
                    url: tk_route_seller,
                    label: 'Vendedor'
                },
                {
                    url: tk_route_seller,
                    label: 'Plenobras'
                }
            ]}/>
            


            <div className="TkSellerInfo__container">
                <h3>Informações sobre o Vendedor</h3>
                <h4>Plenobras Elétricos e Hidráulicos</h4>
                <dl>
                    <dt>Razão Social:</dt>
                        <dd>PLENOBRAS DISTRIBUIDORA ELETRICA E HIDRAULICA LTDA</dd>
                    <dt>CNPJ:</dt>
                        <dd>72.313.828/0001-00</dd>
                    <dt>Numero de Telefone:</dt>
                        <dd><a href={`tel:+555121016800`}>+55 (51) 2101-6800</a></dd>
                    <dt>Endereço:</dt>
                        <dd>Rua Voluntários da Pátria, 2035</dd>
                        <dd>Floresta, Porto Alegre</dd>
                        <dd>Rio Grande do Sul, 90230-011</dd>
                </dl>
            </div>
            <div className="TkSellerInfo__container">
                <h3>Sobre a Plenobras</h3>
                <p>
                    A excelência no atendimento ao cliente vem em primeiro lugar. 
                    Acreditamos no treinamento permanente de nossas equipes, aprimorando relacionamentos e nos especializando em produtos e suas melhores utilizações. 
                    Buscamos a integração com nossos fornecedores para realizar a entrega segura de soluções para cada tipo de projeto, desde a escolha de um equipamento adequado até a indicação de produtos de alta performance para obras e instalações. 
                    Reunimos mais de 50 mil itens entre materiais elétricos, hidráulicos, combate a incêndio, telefonia, ferramentas, automação e segurança produzidos pelas principais marcas do mercado, com produtos à pronta entrega e menor preço. 
                    Marcamos nossa presença oferecendo soluções para todos os segmentos: construção civil, indústria, comércio, serviços e profissionais autônomos. 
                    Estamos sempre disponíveis. 
                    Além do atendimento pessoal, estamos conectados 24 horas por dia com os consumidores na loja online, que possibilita uma compra ágil, intuitiva e segura, com meios de pagamento flexíveis e personalizados. 
                    Criar relações de confiança com o cliente é o que nos move.
                </p>
            </div>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkSellerInfoView
