import dayjs from "dayjs";

const _FULL_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export enum DateFormats {
    ddMMyyyy,
    ddMMyyyyHHmm,
}

const calcDateShipment = (days: number, format = 'DD/MM') => {
    const now = new Date();
    now.setDate(now.getDate() + days);

    return dayjs(now).format(format);
}

const parseDate = (date: string | Date): dayjs.Dayjs => {
    return dayjs(date, _FULL_FORMAT)
}

const formatDateFromString = (date: string | Date, format: DateFormats = DateFormats.ddMMyyyy) => {
    const d = dayjs(date).isValid() ? dayjs(date) : parseDate(date);

    switch (format) {
        case DateFormats.ddMMyyyyHHmm:
            return d.format('DD/MM/YYYY HH:mm')
        case DateFormats.ddMMyyyy:
        default:
            return d.format('DD/MM/YYYY')
    }
}

const isBefore = (from: string | Date, to: string | Date) => dayjs(from, _FULL_FORMAT).isBefore(dayjs(to, _FULL_FORMAT))

export {
    calcDateShipment,
    isBefore,
    parseDate,
    formatDateFromString
}
