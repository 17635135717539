import React, {lazy, Suspense, useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {smoothScroll} from "../../utils/utils";

const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "privacy_policy" */ /* webpackPrefetch: true */ './_privacyPolicy'))

export const tk_route_privacy_policy = '/politica-de-privacidade';

const TkPrivacyPolicyView: React.FC = () => {
    useEffect(() => smoothScroll(), [])

    return <>
        <TkHeadSEO title='Política de Privacidade | Teky'
                   description='Saiba como funciona nossa política de privacidade'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_privacy_policy,
                    label: 'Política de Segurança'
                }
            ]}/>

            <Suspense fallback={<div>Carregando...</div>}>
                <PrivacyPolicy/>
            </Suspense>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkPrivacyPolicyView
