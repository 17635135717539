import React, {FC, FormEvent, memo, useCallback, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import {animated as a, useSpring, useTrail} from "react-spring";
import TkDivider from "../../components/particles/TkDivider";
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView";
import {formatDocument, isEmail} from "../../utils/string-utils";
import {AccountType} from "../../models/user";
import {useHistory, useLocation} from "react-router-dom";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {cardsContent, getIcon, getTitle} from './_utils'
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";
import DeviceStorage from "../../utils/storage-utils";


const Card:FC<{
    type: AccountType
    defaultFeatures: string[]
    plusFeatures?: string[]
}> = ({type, defaultFeatures, plusFeatures}) => {
    const history = useHistory()
    const {search, pathname} = useLocation()
    const [isCheckingPlan, setCheckingPlan] = useState(false)
    const [errors, setErrors] = useState([])
    const email = DeviceStorage.getEmailSignup
    const {bind: bindEmail, value: emailValue} = useSimpleInput(email || '');
    const {getPlan} = useTkUser()
    const {goToPageError} = useTkAppErrors()

    const config = {mass: 5, tension: 2000, friction: 200}
    const trailErrors = useTrail(errors.length, {
        config,
        opacity: 1,
        from: { opacity: 0 },
    });

    const validateEmail = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()


        const p = new URLSearchParams(search)

        setCheckingPlan(true)
        setErrors([])

        try {
            if (isEmail(emailValue)) {
                const user = await getPlan(emailValue);
                DeviceStorage.putEmailSignup(emailValue)

                if (user) {
                    p.set('estagio', 'converter')
                } else {
                    p.set('estagio', 'criar')
                }

                history.push(`${pathname}?${p.toString()}`)
            } else {
                setErrors(['E-mail inválido'])
            }

        } catch (e) {
            goToPageError(e);
        } finally {
            setCheckingPlan(false)
        }
    }, [emailValue, setCheckingPlan, setErrors])


    return <form className={`TkSignUpView__card-email ${type}`} onSubmit={validateEmail}>
        <div className='form'>
            <h2>Vamos começar a criar sua conta {getTitle(type)}</h2>
            <small>Insira o e-mail que deseja usar para sua conta comercial:</small>

            <div className='m-t-20px'>
                <label htmlFor="signUpEmail"
                       style={{
                           margin: 0
                       }}>E-mail <span style={{
                    color: 'red'
                }}>*</span></label>

                <input type="email"
                       id='signUpEmail'
                       disabled={isCheckingPlan}
                       className='form-control form-control-sm'
                       {...bindEmail}/>

                {trailErrors.map((props, index) => (
                    <a.div
                        key={index}
                        className='alert alert-sm alert-danger m-t-20px'
                        style={props}>
                        {errors[index]}
                    </a.div>
                ))}

                <div className='t-a-c m-t-20px'>
                    <button type='submit'
                            disabled={isCheckingPlan}
                            className='TkSignUpView__plan-button'>
                        <TkSvgIcon icon={isCheckingPlan ? 'sync-solid' : getIcon(type)}
                                   className={`m-r-1em ${isCheckingPlan ? 'rotate-1-seg' : ''}`}/>Quero ser {getTitle(type)}
                    </button>
                </div>
                <div className='m-t-40px'>
                    <small>Você está a apenas alguns passos de criar sua conta.
                    Aqui está o que esperar:</small>

                    <br/>
                    <br/>
                    <ul style={{
                        fontSize: '.8em',
                        margin: 0,
                        padding: 0,
                        listStyle: 'none'
                    }}>
                        <li>
                            1. CRIAÇÃO DE CONTA
                        </li>
                        <li>
                            2. DETALHES DO NEGÓCIO
                        </li>
                        <li>
                            3. PROCESSO DE VERIFICAÇÃO
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='desc'>
            <header>
                <TkSvgIcon icon={getIcon(type)}/>
                <h2>{getTitle(type)}</h2>
            </header>

            <div className='content'>
                <ul>
                    {defaultFeatures.map(df => {
                        return <li key={type + df}>
                            <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{df}</span>
                        </li>
                    })}
                </ul>
                {plusFeatures && <div className='t-a-l m-t-20px'>
                    <div className='m-b-20px plus-more'>
                        <TkSvgIcon icon='plus-solid' className='TkSignUpView__icon-size-1'/>&nbsp;&nbsp;&nbsp;e mais
                    </div>
                    <ul>
                        {plusFeatures.map(pf => {
                            return <li key={type + pf}>
                                <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{pf}</span>
                            </li>
                        })}
                    </ul>
                </div>}
            </div>
        </div>
    </form>
}

const _EmailAccount:FC = () => {
    const {search} = useLocation()
    const p = new URLSearchParams(search)
    const type: AccountType = AccountType[p.get('tipo') as keyof typeof AccountType]
    const cc = cardsContent.filter(cc => cc.type === type)[0];


    const effect = useSpring({
        width: '100%',
        from: {
            opacity: 0,
            transform: `translate3d(40px,0,0)`
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0,0,0)'
        }
    });

    return <>
        <TkHeadSEO title='E-mail de cadastro | Teky' description='Informe o e-mail para cadastrar na Teky'/>

        <div className="container m-t-1rem t-a-c">
            <TkLogoBuilder/>

            <div className='TkSignUpView__container m-t-40px'>

                <a.div style={effect} >
                    <Card type={type}
                          defaultFeatures={cc.defaultFeatures}
                          plusFeatures={cc.plusFeatures} />
                </a.div>
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>
}

export default memo(_EmailAccount)
