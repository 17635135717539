import React, {memo} from 'react';
import {isBlank} from "../../../utils/string-utils";
import StripeIcons from '../../../assets/images/stripe-icons.svg'

const TkSvgIcon: React.FC<{
    icon?: string | null,
    className?: string
}> = ({icon = 'search', className}) => {
    if (isBlank(icon)) return <></>;

    const useTag = `<use xlink:href="${StripeIcons}#${icon}" fill="currentColor"/>`;

    return <svg className={className} dangerouslySetInnerHTML={{__html: useTag}}/>
};

export default memo(TkSvgIcon)
