import React, {FC} from 'react'
import {ITkProductModel} from "../../../models/product";
import {useTkMedia} from "../../../context/TkContext";
import Slider, {Settings} from "react-slick";
import TkCarouselNavigationButton from "./_carouselNavigationButton";
import TkCardProduct from "../TkCardProduct";
import _CarouselSkeleton from "./_carouselSkeleton";
import {_useTkCarousel} from "./_useTkCarousel";

const TkCarouselProduct:FC<{
    products: ITkProductModel[]
    isShowSkeleton?: boolean
    showBorders?: boolean
}> = ({products, showBorders, isShowSkeleton}) => {
    const {getSettings} = _useTkCarousel()
    const settings: Settings = getSettings()

    return isShowSkeleton ? <_CarouselSkeleton style={{
        height: 450,
    }}/> : <Slider {...settings}>
        {products.map((product, idx) => {
            return <div key={idx}>
                <TkCardProduct product={product}
                               showSkeleton={isShowSkeleton}
                               showBorders={showBorders}/>
            </div>
        })}
    </Slider>
}

export default TkCarouselProduct