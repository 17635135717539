const DefaultRegExps = {
    email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
};

const diacriticsMap: any = {
    '\u00C0': 'A',
    '\u00C1': 'A',
    '\u00C2': 'A',
    '\u00C3': 'A',
    '\u00C4': 'A',
    '\u00C5': 'A',
    '\u00C6': 'AE',
    '\u00C7': 'C',
    '\u00C8': 'E',
    '\u00C9': 'E',
    '\u00CA': 'E',
    '\u00CB': 'E',
    '\u00CC': 'I',
    '\u00CD': 'I',
    '\u00CE': 'I',
    '\u00CF': 'I',
    '\u0132': 'IJ',
    '\u00D0': 'D',
    '\u00D1': 'N',
    '\u00D2': 'O',
    '\u00D3': 'O',
    '\u00D4': 'O',
    '\u00D5': 'O',
    '\u00D6': 'O',
    '\u00D8': 'O',
    '\u0152': 'OE',
    '\u00DE': 'TH',
    '\u00D9': 'U',
    '\u00DA': 'U',
    '\u00DB': 'U',
    '\u00DC': 'U',
    '\u00DD': 'Y',
    '\u0178': 'Y',
    '\u00E0': 'a',
    '\u00E1': 'a',
    '\u00E2': 'a',
    '\u00E3': 'a',
    '\u00E4': 'a',
    '\u00E5': 'a',
    '\u00E6': 'ae',
    '\u00E7': 'c',
    '\u00E8': 'e',
    '\u00E9': 'e',
    '\u00EA': 'e',
    '\u00EB': 'e',
    '\u00EC': 'i',
    '\u00ED': 'i',
    '\u00EE': 'i',
    '\u00EF': 'i',
    '\u0133': 'ij',
    '\u00F0': 'd',
    '\u00F1': 'n',
    '\u00F2': 'o',
    '\u00F3': 'o',
    '\u00F4': 'o',
    '\u00F5': 'o',
    '\u00F6': 'o',
    '\u00F8': 'o',
    '\u0153': 'oe',
    '\u00DF': 'ss',
    '\u00FE': 'th',
    '\u00F9': 'u',
    '\u00FA': 'u',
    '\u00FB': 'u',
    '\u00FC': 'u',
    '\u00FD': 'y',
    '\u00FF': 'y',
    '\uFB00': 'ff',
    '\uFB01': 'fi',
    '\uFB02': 'fl',
    '\uFB03': 'ffi',
    '\uFB04': 'ffl',
    '\uFB05': 'ft',
    '\uFB06': 'st'
}

const isString = (val: any): boolean => typeof val === 'string' || val instanceof String;
const isBlank = (str: string | undefined | null): boolean => {
    if (!str) return true;
    // @ts-ignore
    return isString(str) && str.trim() === ''
};

const isEmail = (email?: string): boolean => isString(email) && DefaultRegExps.email.test((email || '').toLowerCase());
const ellipsis = (text: string, maxSize: number): string => {
    if (text.length > maxSize) return text.substring(0, maxSize - 3) + '...';
    return text
};

const replaceDiacritics = (str: string) => {
    const result = []
    for (let i = 0; i < str.length; i++) {
        if (diacriticsMap[str[i]]) result.push(diacriticsMap[str[i]])
        else result.push(str[i]);
    }
    return result.join('');
}

const isCpf = (cpf: string | null | undefined): boolean => {
    if (!cpf) return false;

    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11
        || cpf === "00000000000"
        || cpf === "11111111111"
        || cpf === "22222222222"
        || cpf === "33333333333"
        || cpf === "44444444444"
        || cpf === "55555555555"
        || cpf === "66666666666"
        || cpf === "77777777777"
        || cpf === "88888888888"
        || cpf === "99999999999")
        return false;
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;

    return resto === parseInt(cpf.substring(10, 11));
};

const isCnpj = (cnpj: string): boolean => {
    if (!cnpj) return false;

    cnpj = cnpj.replace(/\D/g, '');

    if (cnpj.length !== 14
        || cnpj === "00000000000000"
        || cnpj === "11111111111111"
        || cnpj === "22222222222222"
        || cnpj === "33333333333333"
        || cnpj === "44444444444444"
        || cnpj === "55555555555555"
        || cnpj === "66666666666666"
        || cnpj === "77777777777777"
        || cnpj === "88888888888888"
        || cnpj === "99999999999999")
        return false;
    let tamanho: number = cnpj.length - 2;
    let numeros: string = cnpj.substring(0, tamanho);
    let digitos: string = cnpj.substring(tamanho);
    let soma: number = 0;
    let pos: number = tamanho - 7;

    for (let i: number = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado: number = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i: number = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    return resultado === parseInt(digitos.charAt(1));

};

const formatPostalCode = (postalCode: string | null | undefined): string | null | undefined => {
    if (isBlank(postalCode)) return postalCode;

    // @ts-ignore
    postalCode = postalCode.trim().replace(/\D/g, '');

    if (/\d{8}/gi.test(postalCode)) return postalCode.replace(/(\d{5})(\d{3})/, '$1-$2');

    return null;
};


const formatDocument = (document?: string | null): string | null | undefined => {
    if (isBlank(document)) return document;

    document = document?.trim();

    if (document?.length === 11) return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    else if (document?.length === 14) return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return document;
};

const capitalize = (str: string) => {
    return str.replace(/\b\w/g, (l) => l.toUpperCase());
}

const formatPhone = (phone?: string | null): string | null | undefined => {
    if (isBlank(phone)) return phone;

    phone = phone?.trim();

    if (phone?.length === 8) return phone.replace(/(\d{4})(\d{4})/, '$1-$2');
    else if (phone?.length === 9) return phone.replace(/(\d{5})(\d{4})/, '$1-$2');
    else if (phone?.length === 10) return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    else if (phone?.length === 11) return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

    return null;
};

export {
    isString,
    isBlank,
    isEmail,
    ellipsis,
    isCpf,
    isCnpj,
    formatPostalCode,
    formatPhone,
    formatDocument,
    replaceDiacritics,
    capitalize,
}
