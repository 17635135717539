import React, {lazy, Suspense, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_account} from "../TkMyAccountView";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";
import './style.scss'

const MyData = lazy(() => import('./_myData'))

export const tk_route_my_data = '/sua-conta/meus-dados';


const TkMyDataView: React.FC = () => {
    const location = useLocation()
    const {me, userAuth} = useTkUser();
    const {goToPageError} = useTkAppErrors();
    const [loading, setLoading] = useState(false);
   

    useEffect(() => {
        setLoading(true)
        me().catch(goToPageError)
            .finally(() => setLoading(false));
    }, [me, setLoading, goToPageError]);

    return <>
        <TkHeadSEO title='Meus Dados | Teky'/>

        <TkHeader/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_account,
                    label: 'Sua Conta'
                }, {
                    url: location.pathname,
                    label: 'Meus Dados'
                }
            ]}/>

            <h1>Meus Dados</h1>
        </div>

        <Suspense fallback={<div/>}>
            <MyData userAuth={userAuth} showSkeleton={loading}/>
        </Suspense>

        <TkNewsLetter/>

        <TkFooter/>
    </>
};

export default TkMyDataView
