import React, {CSSProperties, memo, useEffect, useState} from "react"
import './style.scss';
import LoadingImage from '../../../assets/images/tail-spin.svg';
import {isIntersectionObserver} from "../../../utils/utils";

const TkLazyImage: React.FC<{
    src: string
    className?: string | null
    onLoad?: (e: any) => void
    onError?: (e: any) => void
    alt?: string | null
    plainB?: string | null
    width?: number
    height?: number
    style?: CSSProperties
    clickEvent?: any
}> = ({src, className, alt, onError, onLoad, plainB, width, height, style, clickEvent}) => {
    const [imageSrc, setImageSrc] = useState(LoadingImage)
    const [imageRef, setImageRef] = useState();

    const onLoadSrc = (event: any) => {
        if (event.target.src.endsWith(src)) {
            if (onLoad) onLoad(event);
            event.target.classList.add("loaded")
        }
    }

    const onErrorSrc = (event: any) => {
        if (onError) onError(event);

        if (event.target.src.endsWith(src)) {
            event.target.classList.add("has-error")
            event.target.src = plainB
        }
    }

    useEffect(() => {
        let observer: any;
        let didCancel = false

        if (imageRef && imageSrc === LoadingImage) {
            if (isIntersectionObserver()) {
                observer = new IntersectionObserver(entries => {
                        entries.forEach(entry => {
                            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                                didCancel = true;
                                setImageSrc(src);
                                observer.unobserve(imageRef)
                            }
                        })
                    },
                    {
                        root: null,
                        rootMargin: "5px",
                    })
                observer.observe(imageRef)
            } else {
                setImageSrc(src)
            }
        }
        return () => {
            didCancel = true
            if (observer && observer.unobserve) observer.unobserve(imageRef)
        }
    }, [imageRef, imageSrc])

    return <img ref={setImageRef}
                src={imageSrc}
                alt={alt}
                onLoad={onLoadSrc}
                onError={onErrorSrc}
                style={style}
                width={width}
                height={height}
                className={className ? className : 'TkLazyImage'}
                onClick={clickEvent ? clickEvent : () => {}}/>
}


export default memo(TkLazyImage)
