import {AccountType} from "../../models/user";

export const getIcon = (type: AccountType) => {
    switch (type) {
        case AccountType.standard:
            return 'shopping-cart-solid'
        case AccountType.pro:
            return 'tasks-solid'
        case AccountType.business:
            return 'industry-solid'
    }
}

export const getTitle = (type: AccountType) => {
    switch (type) {
        case AccountType.standard:
            return 'Basic'
        case AccountType.pro:
            return 'Pro'
        case AccountType.business:
            return 'Business'
    }
}

const defaultFeatures = [
    'Conta GRÁTIS',
    'Acesse todo o nosso catálogo técnico',
    'Preços e condições especiais',
    
]
export const cardsContent = [{
    type: AccountType.standard,
    title: 'Teky Basic',
    defaultFeatures,
    plusFeatures: [
       
    ]
},
{
    type: AccountType.business,
    title: 'Teky Business',
    defaultFeatures,
    plusFeatures: [
        'Simplifique o processo de compras da sua empresa',
        'Compre e receba produtos em diferentes endereços',
        'Gerencie requisitantes e prestadores de serviço',
    ]
}, {
    type: AccountType.pro,
    title: 'Teky PRO',
    defaultFeatures,
    plusFeatures: [
        'Especifique os produtos para sua obra/projeto', 
        'Gerencie suas listas de produtos e clientes',
        'Envie link com os produtos especificados para seus clientes',
        'Links compartilhados geram bonificaçoes',
    ]
} ]