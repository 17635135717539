import {isSomething} from "../../utils/utils";
import {ClassifiersType} from "./index";

const productCompactDefault = '_id manufacturerName manufacturerId manufacturerReference manufacturerCode price promoPrice stock leadTime isActive isWithdraw unitCommercialName mainName name complement thumbnail stars';
const productSimpleDefault = `total result {${productCompactDefault}}`;

export const productsOutdoor = `query ($name:String!, $pageSize:Int){
                                        productSearch(criteria: {priceOrder: DESC, name: $name, pageSize: $pageSize}) {${productSimpleDefault}}
                                  }`;


export const relatedProducts = `query ($mainName:String!, $divisionId:String, $pageSize:Int){
                                    productSearch(criteria: {mainName: $mainName, divisionId: $divisionId, pageSize: $pageSize}) {${productSimpleDefault}}
                                    }`;


export const bestClassesQuery = `query {
  bestClasses {
    _id name totalQuantity totalValue divisionId divisionName familyId familyName products{
      _id name image totalValue totalQuantity divisionId divisionName familyId familyName groupId groupName
    }
    groups{
      _id name totalValue totalQuantity divisionId divisionName familyId familyName
    }
  }
}`;
export const bestProductsManufacturersQuery = `query ($_ids: [String!]!) {
  bestProductsManufacturers(_ids: $_ids) {
    _id name totalQuantity totalValue products {
      _id name totalQuantity totalValue image
    }
  }
}`;
export const bestProductsCategoriesQuery = `query ($divisionsIds: [String]!, $familiesIds: [String]!, $classesIds: [String]!) {
  bestProductsCategories(divisionsIds: $divisionsIds, familiesIds: $familiesIds, classesIds: $classesIds) {
    _id name image totalValue totalQuantity divisionId familiesIds classesIds
  }
}`;

export const similarityProductsQuery = `query ($externalId: String!) {
  searchSimilarityExternalId(externalId: $externalId) {${productCompactDefault}}
}`;

export const classifiersQuery = (...classifiers: ClassifiersType[]) => {
  const result = ['query {']
  const manufacturerIds = 'manufacturerIds',
      divisionIds = 'divisionIds',
      familyIds = 'familyIds',
      classIds = 'classIds',
      groupIds = 'groupIds';

  function except(name: string) {
    return ['_id name', manufacturerIds, divisionIds, familyIds, classIds, groupIds]
        .filter(nm => nm !== name)
        .join(' ')
  }

  for (const c of classifiers) {
    if (c === ClassifiersType.MANUFACTURERS)
      result.push(`allManufacturers { ${except(manufacturerIds)} } `)

    if (c === ClassifiersType.DIVISIONS)
      result.push(`allDivisions { ${except(divisionIds)} } `)

    if (c === ClassifiersType.FAMILIES)
      result.push(`allFamilies { ${except(familyIds)} } `)

    if (c === ClassifiersType.CLASSES)
      result.push(`allClasses { ${except(classIds)} } `)

    if (c === ClassifiersType.GROUPS)
      result.push(`allGroups { ${except(groupIds)} } `)
  }

  result.push(' } ')

  return result.join(' ')
};
export const productSearchQuery = (
  nameOrder?: string,
  priceOrder?: string
): string => {
  const criteriaArgs = [
    `name: $name, mainName: $mainName, divisionId: $divisionId,
    familiesIds: $familiesIds, classesIds: $classesIds,
    groupsIds: $groupsIds, manufacturersIds: $manufacturersIds,
    stars: $stars, from: $from, pageSize: $pageSize,
    priceStart: $priceStart, priceEnd: $priceEnd, showAvailableStock: $showAvailableStock, showPromotions: $showPromotions`,
  ];

  if (isSomething(nameOrder))
    criteriaArgs.push(
      `nameOrder: ${JSON.parse(nameOrder.toLocaleLowerCase()) ? "ASC" : "DESC"}`
    );
  if (isSomething(priceOrder))
    criteriaArgs.push(
      `priceOrder: ${
        JSON.parse(priceOrder.toLocaleLowerCase()) ? "ASC" : "DESC"
      }`
    );

  return `query ($name:String, $mainName:String, $divisionId: String, $familiesIds: [String],
                    $classesIds: [String], $groupsIds: [String],
                    $manufacturersIds: [String], $stars: [Int],
                    $priceStart: Float, $priceEnd: Float,
                    $pageSize: Int, $from: Int, $showAvailableStock: [String], $showPromotions: [String]){
                productSearch(criteria: {${criteriaArgs.join(", ")}}) {
                ${productSimpleDefault}
                }
          }`
    .replace("\n", "")
    .replace(/\s{2,}/g, " ");
};

export const autoCompleteQuery = `query ($text:String!, $divisionId: String){
                                        searchAutoComplete(text: $text, divisionId: $divisionId) {
                                            total
                                            suggestions {type text}
                                        }
                                  }`;


export const productDetailQuery = `query ($_id: String!){
                                  productDetail(_id: $_id) {
                                    _id isActive isHdr isWithdraw price promoPrice stock leadTime name mainName ean manufacturerName manufacturerId manufacturerCode manufacturerReference thumbnail externalId
                                    hierarchies {
                                      division { _id name }
                                      family { _id name }
                                      class { _id name }
                                      group { _id name }
                                    }
                                    images { _id url }
                                    videos { _id url }
                                    attributes { name value unitMeasure unitMeasureSymbol }
                                    extraInfo { depth height length width volume weight measureUnitName measureUnitSymbol volumeUnitName volumeUnitSymbol weightUnitName weightUnitSymbol }
                                    unitCommercialName
                                    minimumSaleQuantity
                                    controlMultiplicity
                                  }
                              }`;
