import React, {FC, lazy, Suspense, useCallback} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import "./style.scss";
import {useTkMedia} from "../../context/TkContext";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import {useLocation} from "react-router-dom";
import {isBlank, replaceDiacritics} from "../../utils/string-utils";
import {ITkProductQuantityModel} from "../../models/product";
import {isBefore} from "../../utils/date-utils";

const LargeScreen = lazy(() => import("./_largeScreen"));
const SmallScreen = lazy(() => import("./_smallScreen"));

const paramQueryId = "listId";
export const tk_route_my_lists = "/sua-conta/minhas-listas";
export const tk_route_my_list = (id: string) =>
  `${tk_route_my_lists}?${paramQueryId}=${id}`;

const TkMyListsView: FC = () => {
  const {search} = useLocation();
  const {isMediaXs, isMediaSm} = useTkMedia();
  const p = new URLSearchParams(search);
  const name = p.get("nome");

  const filterAndSort = useCallback((listItems: ITkProductQuantityModel[]): ITkProductQuantityModel[] => {
      const hasItems = listItems?.length > 0;

      if (hasItems) {
        let orderedFilteredItems = [...listItems];
        const order = p.get("ordenacao");
        const term = p.get("term");

        if (!isBlank(term)) {
          orderedFilteredItems = orderedFilteredItems.filter(
            ({
               product: {name, manufacturerName, manufacturerReference, ean},
             }) => {
              const text = [name, manufacturerName, manufacturerReference, ean];

              return (
                replaceDiacritics(text.join(" ").toLowerCase()).indexOf(
                  replaceDiacritics(term.toLowerCase())
                ) > -1
              );
            }
          );
        }

        if (order) {
          if (order.startsWith("nome")) {
            orderedFilteredItems.sort((p1, p2) => {
              return order.endsWith("Asc")
                ? p1.product.name.localeCompare(p2.product.name)
                : p2.product.name.localeCompare(p1.product.name);
            });
          } else if (order.startsWith("preco")) {
            orderedFilteredItems.sort((p1, p2) => {
              const sup1 = p1.product;
              const sup2 = p2.product;

              return order.endsWith("Asc")
                ? sup1.price - sup2.price
                : sup2.price - sup1.price;
            });
          } else if (order.startsWith("quantidade")) {
            orderedFilteredItems.sort((p1, p2) => {
              return order.endsWith("Asc")
                ? p1.quantity - p2.quantity
                : p2.quantity - p1.quantity;
            });
          }
        } else {
          const newOrder = [...listItems];
          newOrder.sort((p1, p2) => {
            return isBefore(p1.createdAt, p2.createdAt) ? 1 : -1;
          });
        }

        return orderedFilteredItems;
      }

      return [];
    },
    [search]
  );

  return <>
    <TkHeadSEO
      title={`${
        isBlank(name) ? "Minhas Listas Pro" : `Lista "${name}"`
      } | Teky`}
    />

    <TkHeader/>

    {isMediaXs || isMediaSm ? (
      <Suspense fallback={<div/>}>
        <SmallScreen filterAndSort={filterAndSort}/>
      </Suspense>
    ) : (
      <div className="container m-t-1rem">
        <Suspense fallback={<div/>}>
          <LargeScreen filterAndSort={filterAndSort}/>
        </Suspense>
      </div>
    )}

    <TkNewsLetter/>

    <TkFooter/>
  </>
};

export default TkMyListsView;
