import React, {memo} from "react";
import "./style.scss";
import TkSvgIcon from "../TkSvgIcon";
import {Link, useLocation} from "react-router-dom";
import SiteBlindado from "../../../assets/images/icon-site-blindado.svg";
import LetsEncrypt from "../../../assets/images/icon-lets-encrypt.svg";
import GoogleSafeBrowsing from "../../../assets/images/icon-google-safe-browsing.svg";
import {tk_route_root} from "../../../views/TkHomeView";
import {
  tk_route_be_in_touch,
  tk_var_email,
  tk_var_facebook,
  tk_var_instagram,
  tk_var_phone1,
  tk_var_teky_address1,
  tk_var_teky_document,
  tk_var_teky_name,
  tk_var_whatsapp,
  tk_var_youtube,
} from "../../../views/TkBeInTouchView";
import {tk_route_exchanges_and_devolution} from "../../../views/TkExchangesAndDevolutionView";
import {tk_route_about} from "../../../views/TkAboutView";
import {tk_route_privacy_policy} from "../../../views/TkPrivacyPolicyView";
import {sendAnalyticsEvent, sendSocialInteraction,} from "../../../utils/analytics-utils";
import {formatDocument} from "../../../utils/string-utils";
import {tk_route_withdraw_policy} from "../../../views/TkWithdrawPolicyView";

const TkFooter: React.FC = () => {
  const email = tk_var_email;
  const location = useLocation();
  const linkblog = "https://teky.blog/"
  return (
    <>
      <footer className="TkFooter container">
        <Link
          to={tk_route_root}
          title="Ir para Home Page"
          className="TkFooter__logo"
        >
          Teky
        </Link>
        <div>
          <h3 className="TkFooter__title">Redes Sociais</h3>
          <ul className="TkFooter__social-list">
            <li>
              <a
                href={tk_var_facebook}
                target="_blank"
                className="link-facebook"
                onClick={() =>
                  sendSocialInteraction("Facebook", "click", location.pathname)
                }
                title="Facebook"
                rel="noopener noreferrer"
              >
                <TkSvgIcon icon="facebook-f-brands" />
              </a>
            </li>
            <li>
              <a
                href={tk_var_instagram}
                target="_blank"
                className="link-instagram"
                title="Instagram"
                onClick={() =>
                  sendSocialInteraction("Instagram", "click", location.pathname)
                }
                rel="noopener noreferrer"
              >
                <TkSvgIcon icon="instagram-brands" />
              </a>
            </li>
            <li>
              <a
                href={tk_var_youtube}
                target="_blank"
                className="link-youtube"
                title="YouTube"
                onClick={() =>
                  sendSocialInteraction("Youtube", "click", location.pathname)
                }
                rel="noopener noreferrer"
              >
                <TkSvgIcon icon="youtube-brands" />
              </a>
            </li>
            <li>
              <a
                href={tk_var_whatsapp}
                target="_blank"
                className="link-whatsapp"
                title="WhatsApp"
                onClick={() =>
                  sendSocialInteraction("WhatsApp", "click", location.pathname)
                }
                rel="noopener noreferrer"
              >
                <TkSvgIcon icon="whatsapp-brands" />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="TkFooter__title">Atendimento</h3>
          <ul className="TkFooter__list-map">
            <li>
              <Link to={tk_route_be_in_touch}>Fale Conosco</Link>
            </li>
            <li>
              <Link to={tk_route_exchanges_and_devolution}>
                Trocas e Devoluções
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="TkFooter__title">Institucional</h3>
          <ul className="TkFooter__list-map">
            <li>
              <Link to={{pathname: linkblog}} target="_blank">Blog</Link>
            </li>
            <li>
              <Link to={tk_route_about}>Quem Somos</Link>
            </li>
            <li>
              <Link to={tk_route_privacy_policy}>Política de Privacidade</Link>
            </li>
            <li>
              <Link to={tk_route_withdraw_policy}>Política de Retirada</Link>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="TkFooter__title">Fale Conosco</h3>
          <ul className="TkFooter__list-map">
            <li>
              <a
                onClick={() =>
                  sendAnalyticsEvent(
                    "Telefone",
                    "Discar",
                    tk_var_phone1(true),
                    1
                  )
                }
                href={`tel:${tk_var_phone1()}`}
              >
                {tk_var_phone1(true)}
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  sendAnalyticsEvent("E-mail", "Clique Envio", email, 1)
                }
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="TkFooter__title">Formas de Pagamento</h3>
          <ul className="list-payment">
            <li>
              <TkSvgIcon icon="visacard" />
            </li>
            <li>
              <TkSvgIcon icon="mastercard" />
            </li>
            <li>
              <TkSvgIcon icon="pix" />
            </li>
            <li>
              <TkSvgIcon icon="boleto" />
            </li>
          </ul>
        </div>
        <div>
          <h3 className="TkFooter__title">Site Seguro</h3>
          <ul className="TkFooter__secure">
            <li>
              <img
                src={SiteBlindado}
                width="92.475"
                height="20.691"
                alt="Site Blindado"
              /><br/>
              <img
                src={GoogleSafeBrowsing}
                width="92.475"
                height="31.669"
                alt="Google Safe Browsing"
              /><br/>
              <img
                src={LetsEncrypt}
                width="40.86"
                height="31.669"
                alt="Let\`s Encrypt"
              />
            </li>
          </ul>
        </div>
      </footer>
      <footer className="TkFooter__merchant">
        <p>
          {tk_var_teky_name} | {formatDocument(tk_var_teky_document)}
        </p>
        <p>{tk_var_teky_address1}</p>
        <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
      </footer>
    </>
  );
};

export default memo(TkFooter);
