import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkHeader from "../../components/particles/TkHeader";
import TkFooter from "../../components/particles/TkFooter";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {useTkMedia} from "../../context/TkContext";
import "./style.scss";
import {ReactComponent as Logo} from "../../assets/images/logo-teky-03.svg";
import {tk_route_sign_up_pro} from "../TkSignUpView";

export const tk_route_pro = "/pro";

const TkProView: React.FC = () => {
  const { isMediaXs, isMediaSm, isMediaMd, isMediaLg } = useTkMedia();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getBestImage = useCallback(() => {
    if (isMediaXs) return "sd";
    else if (isMediaSm || isMediaMd) return "mq";
    else if (isMediaLg) return "hq";
    else return "maxres";
  }, [isMediaXs, isMediaSm, isMediaMd, isMediaLg]);

  const [divContent, setDivContent] = useState(
    <div onClick={() => onClick()} className="video-img-container">
      <TkLazyImage
        style={{
          width: "100%",
          height: "auto",
        }}
        src={`https://img.youtube.com/vi/fofkbSPd_tY/${getBestImage()}default.jpg`}
        alt="Video institucional Teky"
      />
      <TkLazyImage
        src="https://teky.s3-sa-east-1.amazonaws.com/icon-play-2.png"
        alt="Play video"
        className="play-button"
      />
    </div>
  );

  const onClick = useCallback(() => {
    setDivContent(
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/fofkbSPd_tY?autoplay=1"
          frameBorder="0"
          allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }, [setDivContent]);

  return (
    <>
      <TkHeadSEO
        title="Teky Pro"
        description="A Teky é um marketplace onde você encontra os menores preços em toda a linha de Abrasivos, Elétricos, Ferramentas... em um só lugar!"
      />

      <TkHeader />

      <section className="TkProView container">
        <h1 className="TkProView__logo">
          <Logo className="TkProView__logo--svg" />
        </h1>

        <p className="TkProView__text-1">
          Indique a Teky para seus clientes!
        </p>

        <div className="row m-b-1rem">
          <div className="col-12 col-md-4">
            <Link
              to={tk_route_sign_up_pro}
              className="TkProView__link button-3"
            >
              Comece agora
            </Link>
          </div>
          <div className="col-12 col-md-8 TkProView__text-2">
            <p>A ferramenta de trabalho para os profissionais técnicos.</p>
            Agilidade e economia para seus clientes.
          </div>
        </div>

        <div className="row m-t-1rem m-b-1rem">
          <div className="col-12 col-md-5">{divContent}</div>
          <div className="col-12 col-md-7 TkProView__text-3">
            Assista o vídeo para saber mais sobre Teky Pro!
          </div>
        </div>

        <div className="TkProView__text-margin">
          <p>Como funciona:</p>
          <p>
            1. Especifique o produto, comparando marcas e preços de diferentes
            fornecedores.
          </p>
          <p>
            2. Adicione o produto à sua lista, clicando em&nbsp;
            <button className="button-3 d-inline">PRO</button>
          </p>
          <p>3. Envie o link com a lista criada para seus clientes.</p>
          <p>
            4. Gestão e organização das listas de orçamento em um dashboard PRO
            exclusivo.
          </p>
          <p>PRONTO! Para começar, é fazer o cadastro no site!</p>

          <div>
            <Link
              to={tk_route_sign_up_pro}
              className="TkProView__link button-3"
            >
              Comece agora
            </Link>
          </div>
        </div>
      </section>
      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkProView;
