import React, {FC, lazy, Suspense} from "react";
import {isBlank} from "../../utils/string-utils";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {useParams} from "react-router-dom";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import {tk_route_account} from "../TkMyAccountView";
import {tk_route_companies} from "../TkMyCompaniesView";

const Form = lazy(() => import('./_form'))

const base = '/sua-conta/empresas';

export const tk_route_company = (isNew = false, companyId?: string) => {
    if (!isBlank(companyId)) return `${base}/editar/${companyId}`;
    if (!isNew) return `${base}/:modo/:document?`;

    return `${base}/nova`;
};


const TkMyCompanyView:FC = () => {
    const params = useParams<any>();


    return <>
        <TkHeadSEO title={(!isBlank(params.document) ? 'Alterar Empresa' : 'Nova Empresa') + ' | Teky'}/>

        <TkHeader/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_account,
                    label: 'Sua Conta'
                },
                {
                    url: tk_route_companies,
                    label: 'Empresas'
                },
                {
                    url: document.location.pathname,
                    label: !isBlank(params.document) ? 'Alterar Empresa' : 'Nova Empresa'
                }
            ]}/>
            <h1>{!isBlank(params.document) ? 'Alterar Empresa' : 'Nova Empresa'}</h1>
        </div>

        <Suspense fallback={<div style={{minHeight: 509}}/>}>
            <Form/>
        </Suspense>

        <TkNewsLetter/>
        <TkFooter/>
    </>
}


export default TkMyCompanyView