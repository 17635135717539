const moneyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

const formatMoney = (value: number | undefined | null): string => {
    if (!value) return moneyFormatter.format(0);

    return moneyFormatter.format(value);
};

export {
    formatMoney
}
