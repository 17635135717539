import React, {lazy, Suspense, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_account} from "../TkMyAccountView";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";
import TkDivider from "../../components/particles/TkDivider";


const MyType = lazy(() => import('./_myType'))

export const tk_route_my_type = '/sua-conta/planos';


const TkMyTypeView: React.FC = () => {
    const location = useLocation()
    const history = useHistory();
    const {me, userAuth} = useTkUser();
    const {goToPageError} = useTkAppErrors();
    const [loading, setLoading] = useState(false);
    const {user}= userAuth;

    useEffect(() => {
        setLoading(true)
        me().catch(goToPageError)
            .finally(() => setLoading(false));
    }, [me, setLoading, goToPageError]);

    return <>
        <TkHeadSEO title='Planos | Teky'/>

        <TkHeader/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_account,
                    label: 'Sua Conta'
                }, {
                    url: location.pathname,
                    label: 'Planos'
                }
            ]}/>

            <h1>Meu Plano</h1>
        </div>

        <Suspense fallback={<div/>}>
            <MyType userAuth={userAuth}></MyType>
        </Suspense>

        

                <TkDivider style={{ marginTop: 100 }} />
               
        <TkNewsLetter/>

        <TkFooter/>
    </>
};

export default TkMyTypeView
